.select {
    &--inline-m {
        @extend .select--inline;

        @include mq($max-screen: $screen-m - 1) {
            display: block;
        }

        .select2 {
            flex: 1;
        }
    }

    &--light,
    &--white {
        .select2-container {
            .select2-selection--single {
                border: none;

                .select2-selection__rendered {
                    border: none;
                }
            }
        }
    }

    &--light {
        .select2-selection--single {
            background: $bg-color-secondary;
        }
    }

    &--white {
        .label {
            color: $white;
        }

        &:focus-within {
            .label {
                color: $white;
            }
        }
    }
}
