.footer {
    &__newsletter {
        border-width: 0;

        @include mq($screen-m) {
            padding-bottom: $spacer--extra-large;
            border-width: $footer__newsletter-border-width;
        }

        @include mq($screen-xl) {
            padding: 0 70px $spacer--extra-large 36px;
        }
    }

    &__social {
        padding: $spacer--semi-medium 0;
        margin: 0;

        @include mq($screen-m) {
            padding: $spacer--semi-large 0 0;
        }

        @include mq($screen-l) {
            padding: 0 0 $spacer--extra-large $spacer--large;
        }

        @include mq($screen-xl) {
            padding: 0 36px $spacer--extra-large 72px;
        }
    }

    &__social-handler {
        align-items: flex-start;
    }

    &__social-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include mq($screen-m) {
            flex-direction: row;
            align-items: center;
        }

        @include mq($screen-l) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__social-list {
        justify-content: flex-start;

        @include mq($screen-xl) {
            justify-content: center;
        }
    }

    &__social-list-title {
        @include mq($screen-l) {
            padding-bottom: 0;
        }
    }

    &__social-list-icon-link {
        border-width: $border-width-base;
    }

    &__contact {
        display: inline-flex;
        flex-direction: column;
        flex: 1;
        padding: $spacer--extra-large 0;
        color: $white;
        border: $footer__newsletter-border;
        border-width: $footer__newsletter-border-width;
        white-space: nowrap;

        @include mq($screen-l) {
            flex-basis: 30%;
            border: none;
            padding: $spacer--semi-medium 0 0;
            margin-right: $spacer--semi-large;
        }
    }

    &__contact-wrapper {
        height: 100%;

        @include mq($screen-m) {
            columns: 2;
        }

        @include mq($screen-l) {
            display: contents;
        }
    }

    &__contact-title {
        margin-bottom: $spacer--medium;
        color: currentColor;
        font-weight: $font-weight-bold;
        font-size: $font-size-large;
    }

    &__contact-content {
        display: inline-flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: $font-size-large;
        flex: 1;

        a {
            color: currentColor;
            text-decoration: underline;
        }

        @include mq($screen-m) {
            display: contents;
        }

        @include mq($screen-l) {
            display: inline-flex;
        }
    }

    &__links {
        display: inline-flex;
        flex-wrap: wrap;

        @include mq($screen-l) {
            flex-wrap: nowrap;
        }

        @include mq($screen-xl) {
            padding-left: 36px;
            padding-right: 36px;
        }

        .dropdown-list {
            overflow: hidden;

            &__label {
                padding: $spacer--semi-medium 0;
                font-size: $font-size-large;
            }

            &__list {
                margin-bottom: 0;

                @include mq($screen-m) {
                    padding: $spacer--large 0;
                }

                @include mq($screen-l) {
                    margin-bottom: inherit;
                    padding: 0;
                }
            }

            &__item {
                margin-right: 36px;
                overflow: hidden;

                &:first-child {
                    width: 100%;
                }

                &:last-child {
                    margin-right: 0;
                }

                .list__column-item {
                    padding: 0 0 26px;

                    &:last-of-type {
                        padding: 0;
                    }
                }
            }
        }
    }

    &__bottom-bar-handler {
        @include mq($screen-m) {
            align-items: center;
        }
    }

    &__copyright {
        @include mq($screen-l) {
            padding: 0;
        }
    }

    &__payments-list {
        @include mq($screen-m) {
            justify-content: space-around;
        }
    }

    &--checkout {
        .footer__bottom-bar-handler {
            justify-content: center;
        }

        .footer__payments-list {
            display: none;
        }
    }
}
