.gallery {
    max-width: 467px;
    margin: 0 auto;

    @include mq($screen-l) {
        margin: 0 0 0 auto;
    }

    &__placeholder-image {
        padding: 0 !important; //sass-lint:disable-line no-important
        max-width: 467px;
        max-height: 467px;

        &.ratio-image {
            transform: translate(-50%, -50%);

            @include mq($screen-l) {
                transform: translate(-50%, -50%);
            }
        }
    }
}
