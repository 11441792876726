.actions-group {
    &--edit-account {
        margin-top: $spacer--extra-large;
        justify-content: flex-start;

        @include mq($screen-l) {
            margin-top: $spacer--large;
        }
    }

    &__handler {
        &--back {
            display: flex;
            justify-content: center;
        }
    }

    &__link {
        color: $blue;

        &--back {
            color: $navy;
            font-size: $font-size-base;
            text-decoration: none;
            font-weight: $font-weight-bold;
            letter-spacing: normal;
            padding: $spacer 0;
        }
    }

    &__button {
        &--edit-account,
        &--edit-account:last-child {
            @include mq($screen-l) {
                width: 208px;
                margin-right: $spacer--large;
            }
        }
    }
}
