// sass-lint:disable no-important
.submenu {
    &--mobile-hidden {
        display: none !important;

        @include mq($screen-l) {
            display: flex !important;
        }
    }

    &__list {
        padding: $spacer--medium 0 0;
        max-width: $max-content-width;

        @include mq($screen-m) {
            flex-direction: row !important;
            justify-content: center;
            align-items: center;
            width: 100% !important;
            height: 100%;
            margin: 0 auto 0 0;
            padding: 0 $spacer;
        }

        @include mq($screen-l) {
            padding: 0 $spacer--semi-large;
        }

        @include mq($screen-xl) {
            margin: 0 auto;
            padding: 0;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: column;

        @include mq($screen-m) {
            flex-direction: row;
            flex-grow: 1;
            height: 100%;
        }
    }

    &__button {
        height: 56px;
        display: inline-flex !important;
        align-items: center;

        @include mq($screen-m) {
            min-height: 96px;
            height: auto;
        }

        @include mq($screen-xl) {
            flex-basis: auto;
        }

        a {
            justify-content: flex-start;
            width: 100%;
            height: 100%;
            padding: $spacer--medium;
            text-decoration: none;
            font-size: $font-size-medium;
            text-align: center;
            font-weight: $font-weight-base;
            border-bottom: 3px solid transparent;

            @include mq($screen-m) {
                padding: $spacer--small;
                justify-content: center;
            }

            @include mq($screen-l) {
                padding: $spacer--semi-medium;
                font-size: $font-size-large;
            }

            &:before,
            &:after {
                content: none;
            }

            &:hover,
            &:focus,
            .focus-visible {
                background-color: $gray-lighter;
                color: $blue;
                border-bottom: 3px solid $blue;
                outline: none;
                // Text shadow instead of font-weight:bold to prevent layout shifts.
                text-shadow: 0 0 0.65px $gray-dark, 0 0 0.65px $blue;
            }

            picture {
                margin: 0 $spacer 0 0;
            }

            .icon {
                @include mq($screen-l) {
                    width: 34px;
                    height: 34px;
                }

                img {
                    @include mq($screen-l) {
                        max-width: 34px !important;
                        height: 34px !important;
                    }
                }
            }

            [data-element="link_text"] {
                text-align: left;
                line-height: 24px;
                min-height: 34px;
                display: inline-flex;
                align-items: center;
            }
        }

        &--secondary {
            a {
                &:hover,
                &:focus,
                .focus-visible {
                    background-color: $gray-light;
                }
            }
        }
    }

    &__heading {
        padding: 0 $spacer;
        font-size: $font-size-large-2;
        color: $color-primary;
        font-weight: $font-weight-bold;
        white-space: nowrap;

        @include mq($screen-m) {
            padding: 0 $spacer 6px;
        }

        @include mq($screen-l) {
            padding: 0 0 3px;
        }

        &[data-content-type='heading'] {
            margin-bottom: $spacer--semi-medium;

            @include mq($screen-m) {
                margin: 0;
            }

            @include mq($screen-l) {
                margin: 0 $spacer--semi-medium 0 0;
            }
        }
    }

    &__contact {
        margin: 0 0 $spacer 0;

        @include mq($screen-l) {
            margin: 0;
        }

        [data-content-type="button-item"] {
            width: 100%;

            a {
                width: 100%;
            }
        }
    }
}
