// sass-lint:disable no-misspelled-properties, no-important

.full-width-banner {
    .pagebuilder-column-line {
        align-items: center;
        justify-content: end;
        flex-direction: column-reverse !important;

        @include mq($screen-l) {
            flex-direction: row !important;
            gap: 0;
        }

        @include mq($screen-xl) {
            gap: 120px;
        }
    }

    &__image-column {
        flex: 0 45%;
        width: auto !important;

        @include mq($screen-l) {
            justify-content: center !important;
        }
    }

    &__image {
        margin: 0;
        width: auto;
        height: 100%;

        picture {
            height: 100%;
        }

        img {
            width: 100%;
            height: 100% !important;
            object-fit: cover;
            max-height: 255px;

            @include mq($screen-m) {
                max-height: 354px;
            }

            @include mq($screen-l) {
                max-height: initial;
            }
        }
    }

    &__content {
        width: 100% !important;
        padding: $spacer--semi-large $spacer--medium;

        @include mq($screen-m) {
            padding: $spacer--semi-large;
        }

        @include mq($screen-l) {
            padding: 84px $spacer--semi-large;
        }

        @include mq($screen-xl) {
            max-width: calc(#{$max-content-width} / 2);
            margin-left: calc((100vw - #{$max-content-width}) / 2);
            padding-left: 0;
            padding-right: 0;
        }

        &--text-only {
            @include mq($screen-xl) {
                margin-left: auto;
                padding: 84px 84px 84px 0;
            }
        }

        &--points {
            line-height: 1.5;

            @include mq($screen-xl) {
                max-width: none;
                margin: 0;
                padding: 84px;
            }

            ul {
                margin: 0;
                padding-inline-start: $spacer--semi-medium;
                font-size: $font-size-medium;

                @include mq($screen-m) {
                    font-size: $font-size-large-2;
                }
            }
        }
    }

    .pagebuilder-button-primary {
        width: 300px;
        margin-top: $spacer--semi-medium;
    }

    &__note {
        h3 {
            font-size: $font-size-large-2;
            color: $blue;
        }

        p {
            font-size: $font-size-large;
        }
    }

    &__text {
        p {
            margin: 0;
        }
    }

    &__industires-links {
        margin-top: $spacer--semi-large;
        line-height: 1.7;

        a {
            margin-right: $spacer--medium;
            color: $blue;
            text-decoration: underline;
            font-weight: $font-weight-medium;

            &:first-of-type {
                margin-left: $spacer--medium;
            }
        }
    }

    &--notes-grid {
        .full-width-banner__content {
            display: grid !important;
            grid-template-columns: 1fr;
            grid-auto-rows: min-content;
            gap: $spacer--semi-medium;

            @include mq($screen-m) {
                grid-template-columns: repeat(2, 1fr);
                gap: $spacer--semi-large;
            }

            @include mq($screen-l) {
                grid-template-columns: repeat(3, 1fr);
                padding-left: $spacer--semi-large;
                padding-right: $spacer--semi-large;
            }

            @include mq($screen-xl) {
                grid-template-columns: repeat(2, 1fr);
                gap: $spacer--extra-large;
            }
        }

        [data-content-type='heading'] {
            margin-bottom: 0;

            @include mq($screen-m) {
                grid-column-start: 1;
                grid-column-end: 3;
            }

            @include mq($screen-l) {
                grid-column-start: 1;
                grid-column-end: 4;
            }

            @include mq($screen-xl) {
                grid-column-start: 1;
                grid-column-end: 3;
            }
        }
    }

    &--reversed {
        .pagebuilder-column-line {
            justify-content: flex-start;

            @include mq($screen-l) {
                flex-direction: row-reverse !important;
            }
        }

        .full-width-banner__content {
            @include mq($screen-xl) {
                margin-left: 0;
                margin-right: calc((100vw - #{$max-content-width}) / 2);
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    &--half-to-half-ratio {
        .pagebuilder-column-line {
            @include mq($screen-l) {
                gap: 0;
            }
        }

        .full-width-banner__image-column {
            flex: 0 0 50%;
        }

        .full-width-banner__content {
            @include mq($screen-l) {
                padding-left: $spacer--semi-large;
                padding-right: 64px;
            }

            @include mq($screen-xl) {
                justify-content: center !important;
                padding: 110px 184px 110px $spacer;

            }
        }

        &.full-width-banner--reversed {
            .full-width-banner__content {
                @include mq($screen-l) {
                    padding-right: $spacer--semi-large;
                    padding-left: 64px;
                }

                @include mq($screen-xl) {
                    padding: 110px $spacer 110px 184px;
                }
            }
        }
    }

    &--text-only {
        .pagebuilder-column-line {
            flex-direction: column !important;

            @include mq($screen-l) {
                flex-direction: row !important;
                gap: 0;
            }

            .full-width-banner__content {
                @include mq($screen-xl) {
                    width: 50% !important;
                }
            }
        }
    }

    &--content-secondary-spacing {
        .full-width-banner__content {
            padding-top: $spacer--semi-large;
            padding-bottom: $spacer--semi-large;

            @include mq($screen-l) {
                padding-top: 84px;
                padding-bottom: 84px;
            }

            @include mq($screen-xl) {
                padding-right: 84px;
            }
        }

        &.full-width-banner--reversed {
            .full-width-banner__content {
                @include mq($screen-xl) {
                    padding-left: 84px;
                }
            }
        }
    }
}
