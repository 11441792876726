// sass-lint:disable no-misspelled-properties

.compare {
    &__subheading {
        font-size: $font-size-large;
        line-height: 48px;
        font-weight: $font-weight-normal;
        color: $gray-darker;
    }

    &__label {
        text-align: left;
        font-size: $font-size-medium;
        font-weight: $font-weight-bold;
        color: $color-primary;
    }

    &__cell {
        font-size: $font-size-medium;
        padding: $spacer--medium $spacer;

        @include mq($screen-m) {
            padding: $compare__cell-padding;
        }

        p {
            font-size: $font-size-medium;
        }
    }

    &__link {
        color: $color-primary;
    }

    &__buttons-container {
        @include mq($screen-l) {
            gap: $spacer;
        }
    }

    &__secondary-buttons {
        gap: $spacer;

        .button {
            background-color: $gray-lighter;
        }
    }

    &__form {
        flex: 1;
    }

    &__cart {
        white-space: nowrap;
    }
}
