// sass-lint:disable no-misspelled-properties

.product-grid {
    width: 100%;
    max-width: $max-content-width;
    padding: $spacer--extra-large $spacer;
    margin: 0 auto;

    @include mq($screen-m) {
        padding-left: $spacer--semi-large;
        padding-right: $spacer--semi-large;
    }

    @include mq($screen-xl) {
        padding: 84px 0 92px;

    }

    .pagebuilder-column-line {
        flex-flow: column-reverse;
        margin-bottom: $spacer--extra-large;

        @include mq($screen-m) {
            flex-flow: row;
            margin-bottom: $spacer--extra-large;
        }

        @include mq($screen-l) {
            margin-bottom: 110px;
        }
    }

    .pagebuilder-column {
        width: 100% !important;

        @include mq($screen-l) {
            width: 50% !important;
        }
    }

    &__content {
        justify-content: center;

        [data-content-type="text"] {
            font-size: $font-size-large;
            margin-bottom: $spacer--extra-large;

            ul {
                margin: 0;
                padding-left: $spacer--large;
            }
        }
    }

    &__image {
        justify-content: center !important;
        margin: 0 0 $spacer--extra-large 0;

        @include mq($screen-m) {
            margin-bottom: 0;
        }

        img {
            max-height: 222px;
            height: auto;

            @include mq($screen-m) {
                max-height: 348px;
            }

        }
    }

    &__products {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: $spacer--medium;

        @include mq($screen-s) {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @include mq($screen-l) {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }

        @include mq($screen-xl) {
            gap: $spacer--large;
        }

        .product-grid-item {
            border-width: 1px;
            height: 100%;
        }

        .product-grid-item__image-wrapper {
            picture {
                min-height: 100%;
            }

            img {
                max-height: 100%;
                object-fit: contain;
                padding: $spacer--small;

                @include mq($screen-m) {
                    padding: $spacer--large;
                }
            }
        }

        .product-grid-item__name {
            min-height: initial;
        }

        .product-grid-item__name-link {
            min-height: initial;
            font-size: $font-size-large;
            font-weight: $font-weight-base;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            @include mq($screen-m) {
                font-size: $font-size-extra-large-2;
            }
        }

        .product-grid-item__details-wrapper {
            margin: $spacer 0;
            padding: 0 $spacer;
        }

        .product-grid-item__details {
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            margin: 0;
            padding: 0;
        }



        .button--link {
            color: $blue;
            padding: 0;
            font-weight: $font-weight-medium;
            margin-top: $spacer;
            min-height: auto;
            font-size: $font-size-medium;
            line-height: 26px;
            text-align: left;

            @include mq($screen-xl) {
                font-size: $font-size-large;
                line-height: 40px;
            }
        }
    }
}
