.specification {
    padding: $spacer--extra-large $spacer;

    @include mq($screen-m) {
        padding: 84px $spacer--semi-medium;
    }

    @include mq($screen-l) {
        padding: 84px $spacer--semi-large;
    }

    @include mq($screen-xl) {
        padding: 84px 0;
    }

    &--toggle-list {
        .specification__dropdown-list {
            display: none;
        }

        .specification__dropdown-button {
            &::before {
                content: '+';
            }
        }
    }

    &__main {
        @include mq($screen-xl) {
            display: grid !important; //sass-lint:disable-line no-important
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto 1fr;
            column-gap: 130px;
        }
    }

    &__image {
        width: calc(100vw + #{$spacer--extra-small});
        margin: 0 0 $spacer--large calc(50% - 50vw);

        @include mq($screen-m) {
            width: auto;
            margin: 0 0 64px 0;
        }

        @include mq($screen-xl) {
            grid-row: 1 / 3;
            grid-column: 2;
            margin: 0 0 $spacer--semi-large 0;
        }

        img {
            width: 100%;

            @include mq($screen-m) {
                max-height: 354px;
                object-fit: cover;
            }

            @include mq($screen-xl) {
                max-height: none;
            }
        }
    }

    &__title {
        @include mq($screen-xl) {
            grid-row: 1;
            grid-column: 1;
        }
    }

    &__list {
        margin: 0 0 $spacer--semi-large 0;

        @include mq($screen-m) {
            margin: 0 0 $spacer--extra-large 0;
        }

        @include mq($screen-xl) {
            grid-row: 2;
            grid-column: 1;
        }

        ul {
            margin: 0;
            padding-left: 20px;
            font-size: $font-size-medium;

            @include mq($screen-m) {
                font-size: $font-size-large;
            }

            li {
                margin-bottom: $spacer--small;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__bottom-buttons {
        [data-content-type="button-item"] {
            width: 100%;

            a {
                width: 100%;
                height: 56px;

                @include mq($screen-m) {
                    width: auto;
                }
            }
        }
    }

    &__dropdown {
        background-color: $gray-lighter;
    }

    &__dropdown-button {
        position: relative;
        width: 100%;
        padding-left: 78px;
        cursor: pointer;

        div {
            margin: 0;
            padding: 0;
            min-height: 78px;
            text-decoration: none;
            font-size: 20px;
            width: 100%;
            text-align: left !important; //sass-lint:disable-line no-important
            cursor: pointer !important; //sass-lint:disable-line no-important

            &:hover {
                color: $color-primary;
            }

            span {
                width: 100%;

                &:hover {
                    color: $color-primary;
                }
            }
        }

        &::before {
            position: absolute;
            left: 30px;
            top: 16px;
            font-size: $font-size-extra-large-2;
            font-weight: $font-weight-bold;
            content: '-';
        }
    }

    &__dropdown-list {
        padding: $spacer $spacer--large 0;

        ul {
            margin: 0 0 $spacer--large 0;
            padding-left: 20px;
            font-size: $font-size-medium;

            @include mq($screen-m) {
                font-size: $font-size-large;
            }

            li {
                margin-bottom: $spacer--small;
                color: $color-primary;

                ul {
                    margin: $spacer 0 0 0;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .pagebuilder-column-group {
        max-width: $max-content-width;

        @include mq($screen-xl) {
            margin: auto;
        }
    }

    // Due to pagebuilder limitations we need to
    // target specification__dropdown column line
    .pagebuilder-column-line:nth-child(2) {
        width: calc(100vw + #{$spacer--extra-small}) !important; //sass-lint:disable-line no-important
        margin: 0 0 $spacer--semi-large calc(50% - 50vw);

        @include mq($screen-m) {
            width: auto !important; //sass-lint:disable-line no-important
            margin: 0 0 $spacer--large 0;
        }
    }
}
