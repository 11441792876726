// Styles

.form-edit-account {
    .fieldset {
        float: none !important; // sass-lint:disable no-important

        .legend {
            font-size: $font-size-medium;
            font-weight: $font-weight-medium;
            text-transform: uppercase;
        }

        .field {
            .label {
                display: inline-block;
            }

            .input-text,
            .comment {
                @include mq($screen-m) {
                    width: 50%;
                }
            }

            .comment {
                font-size: $font-size-base;
            }
        }
    }

    .actions-toolbar {
        @include mq(false, $screen-m) {
            .button {
                width: 100%;
            }
        }
    }

    .fieldset {
        .checkbox {
            vertical-align: middle;
        }

        .help {
            width: 50%;
            padding-left: $spacer--medium;
            padding-bottom: $spacer--medium;
            line-height: 1.2;
            color: $gray-dark;
        }
    }
}
