// sass-lint:disable no-important
.text-white {
    color: $white;
}

.text-blue {
    color: $blue;
}

.bg-color-secondary {
    background: $bg-color-secondary;
}

.text-navy {
    color: $navy;
}

.display-block {
    display: block;
}

.full-width {
    width: 100vw !important;
    margin-left: calc(50% - 50vw) !important;

    @include mq($screen-xl) {
        // prevent horizontal scroll on desktop
        width: calc(100vw - #{$spacer--extra-small}) !important;
    }
}

.content-max-width {
    max-width: $max-content-width !important;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.mb-48 {
    margin-bottom: 48px !important;
}

.mb-84 {
    margin-bottom: 84px !important;
}

.mb-96 {
    margin-bottom: 94px !important;
}

.mb-110 {
    margin-bottom: 110px !important;
}

.mx-8 {
    margin-right: 8px !important;
    margin-left: 8px !important;
}

.mx-24 {
    margin-right: 24px !important;
    margin-left: 24px !important;
}

.mx-40 {
    margin-right: 40px !important;
    margin-left: 40px !important;
}

.mx-48 {
    margin-right: 48px !important;
    margin-left: 48px !important;
}

.ml-0 {
    margin-left: 0 !important;
}

.bg-color-secondary {
    background: $bg-color-secondary;
}

.text-weight-medium {
    font-weight: $font-weight-medium;
}

.text-size-large {
    font-size: $font-size-large;
}

.last-paragraph-m-0 {
    & p:last-child {
        margin: 0;
    }
}

.text-navy {
    color: $navy;
}

.display-block {
    display: block;
}

.no-border {
    border: none;
}
