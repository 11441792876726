.active-filters {
    &__heading {
        font-size: $font-size-large;
        text-transform: uppercase;

        @include mq($screen-l) {
            font-size: $font-size-extra-large;
        }
    }

    &__item {
        font-size: $font-size-medium;
    }
}
