.product-grid-item {
    &__rating {
        display: none;
    }

    &__name {
        min-height: 48px;
        margin-bottom: $spacer--medium;
    }

    &__details-bottom {
        flex-flow: column;
    }

    &.product-grid-item--slider-item {
        border-width: $border-width-base;

        .product-grid-item {
            &__name {
                font-size: $font-size-large;
                margin: 0;
            }

            &__rating {
                display: none;
            }

            &__price {
                margin: 0;
            }

            &__details {
                padding: 0 $spacer--extra-small;

                @include mq($screen-m) {
                    margin-top: $spacer--medium;
                    padding-left: $spacer--medium;
                    padding-right: $spacer--medium;
                }
            }
        }
    }

    &__see-more-button {
        padding: 0 $spacer--medium;
        margin-top: $spacer--extra-small;
        min-width: 134px;
        min-height: 54px;

        @include mq($screen-l) {
            margin-top: $spacer;
            min-width: 137px;
            min-height: 56px;
        }

        @include mq($screen-xl) {
            margin-top: $spacer--semi-medium;
            min-width: 196px;
            min-height: 48px;
        }
    }

    &__price {
        width: 100%;
        flex-basis: 100%;
        justify-content: flex-end;
        margin: 0 0 $spacer--medium;
        gap: $spacer; // sass-lint:disable-line no-misspelled-properties

        .price {
            display: flex;
            gap: $spacer; // sass-lint:disable-line no-misspelled-properties
        }

        .price__value {
            margin-right: $spacer;
        }

        .minimal-price {
            white-space: normal;
        }
    }

    &__actions {
        width: 100%;
        flex-basis: 100%;
        justify-content: space-between;

        @include mq($screen-m) {
            width: 100%;
        }

        &--ee {
            justify-content: flex-end;

            @include mq($screen-m) {
                justify-content: space-between;
            }

            .button--wishlist {
                display: block;
            }
        }
    }

    &--recently-viewed {
        border: $border-base;
        padding: 0 $spacer $spacer;

        &:not(:root:root) {
            display: flex;
        }

        @include mq($screen-m) {
            padding: 0 $spacer--medium $spacer--medium;
        }

        .product-grid-item {
            &__name {
                min-height: 54px;
                margin: 0 0 $spacer;
                font-size: $font-size-large;
            }

            &__name-link {
                color: $gray-darker;
            }

            &__actions {
                display: none;
            }

            &__price {
                width: 100%;
                margin: 0;
            }

            &__details {
                margin: $spacer 0 0;
                padding: 0;
            }
        }
    }
}
