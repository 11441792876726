// sass-lint:disable no-important
// important flags are required for page builder

.section {
    &--spacer-small {
        margin-bottom: $spacer--extra-large !important;

        @include mq($screen-m) {
            margin-bottom: 84px !important;
        }

        @include mq($screen-xl) {
            margin-bottom: 84px !important;
        }
    }

    &--spacer-medium {
        margin-bottom: $spacer--extra-large !important;

        @include mq($screen-m) {
            margin-bottom: 84px !important;
        }

        @include mq($screen-xl) {
            margin-bottom: 110px !important;
        }
    }

    &--spacer-large {
        margin-bottom: $spacer--extra-large !important;

        @include mq($screen-m) {
            margin-bottom: 110px !important;
        }

        @include mq($screen-xl) {
            margin-bottom: 110px !important;
        }
    }

    &--spacer-large-y {
        margin-top: $spacer--extra-large !important;
        margin-bottom: $spacer--extra-large !important;

        @include mq($screen-m) {
            margin-top: 110px !important;
            margin-bottom: 110px !important;
        }

        @include mq($screen-xl) {
            margin-top: 110px !important;
            margin-bottom: 110px !important;
        }
    }

    &--bg-gray {
        background: $gray-lighter;
        padding: $spacer--extra-large 0 64px;

        @include mq($screen-l) {
            padding: 84px 0;
        }

        @include mq($screen-xxl) {
            padding: 110px 0;
        }
    }

    &--hidden-overflow-x {
        overflow-x: hidden;
    }

    &__heading {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 48px;
        margin: 0 0 $spacer--extra-large;

        @include mq($screen-l) {
            margin: 0 0 84px;
        }

        &--left {
            justify-content: space-between;
        }
    }

    &__content {
        &--products {
            margin: (-$spacer) (-$spacer) 0;
        }
    }

    &__blog-link {
        min-width: 84px;
        margin: 0 0 0 $spacer--medium;
    }
}
