// sass-lint:disable no-important
.content-with-bg {
    background-size: 0 !important;
    padding-bottom: $spacer--extra-large;
    padding-top: $spacer--extra-large;

    @include mq($screen-m) {
        padding-bottom: 110px;
        padding-top: 110px;
        background-size: auto 55% !important;
    }

    @include mq($screen-l) {
        background-size: auto 60% !important;
    }

    @include mq($screen-xl) {
        background-size: auto clamp(100px, 75%, 452px) !important;
    }

    &__heading {
        margin: 0 0 $spacer--large 0;
        font-size: $font-size-large-2;
        letter-spacing: 0.69px;

        @include mq($screen-l) {
            font-size: $font-size-extra-large-2;
        }
    }

    &__text {
        max-width: 770px;

        p {
            @include mq($screen-l) {
                font-size: $font-size-large-2;
            }

            &:last-child {
                margin: 0;
            }
        }
    }

    &__button {
        div {
            height: 56px;
        }

        .pagebuilder-button-link {
            padding: 0;
            text-align: left;
            justify-content: flex-start;
            text-decoration-color: $blue;

            span {
                color: $blue;
                font-size: $font-size-large-2;
                font-weight: $font-weight-base;
            }
        }
    }

    &__link {
        a {
            color: $blue;
        }

        p:last-child {
            margin: 0;
        }
    }

    .pagebuilder-column-group {
        margin: 0 auto;
        width: 100%;
        max-width: $max-content-width;

        .pagebuilder-column {
            margin: 0 $spacer;
            max-width: 790px;

            @include mq($screen-m) {
                margin: 0 $spacer--semi-large;
            }

            @include mq($screen-l) {
                margin: 0 auto 0 $spacer--semi-large;
            }

            @include mq($screen-xl) {
                margin: 0 auto 0 0;
            }
        }
    }

    &--v2 {
        @include mq($screen-m) {
            padding-top: 84px;
            padding-bottom: 84px;
        }

        .pagebuilder-column-group {
            width: auto;

            .pagebuilder-column {
                max-width: 600px;

                @include mq($screen-l) {
                    margin: 0 auto;
                }
            }
        }

        .content-with-bg {
            &__heading {
                margin: 0 0 $spacer--semi-medium 0;
            }

            &__text {
                margin: 0 0 $spacer--large 0;
            }
        }
    }

    &--v3 {
        background-size: auto 55% !important;

        @include mq($screen-m) {
            padding-top: 84px;
            padding-bottom: 84px;
        }

        @include mq($screen-l) {
            background-size: auto 60% !important;
        }

        @include mq($screen-xl) {
            background-size: auto clamp(100px, 75%, 452px) !important;
        }

        .pagebuilder-column-group {
            .pagebuilder-column {
                max-width: 600px;
            }

            .content-with-bg {
                &__heading {
                    margin: 0 0 $spacer--semi-medium 0;
                }

                &__text {
                    margin: 0 0 $spacer--large 0;
                }
            }
        }
    }

    &--v4 {
        padding-top: 74px;
        padding-bottom: 74px;
        background-size: auto 55% !important;

        @include mq($screen-m) {
            padding-top: 84px;
            padding-bottom: 84px;
        }

        @include mq($screen-l) {
            background-size: auto 70% !important;
        }

        @include mq($screen-xl) {
            background-size: auto clamp(100px, 90%, 452px) !important;
        }

        .pagebuilder-column-line {
            flex-direction: column;

            @include mq($screen-m) {
                display: grid !important;
                grid-template-columns: repeat(3, auto);
                grid-template-rows: repeat(2, auto);
                row-gap: $spacer--semi-large; // sass-lint:disable-line no-misspelled-properties
                column-gap: $spacer--medium;
                margin: 0 $spacer--extra-large !important;
                width: auto !important;
            }

            @include mq($screen-xl) {
                max-width: $max-content-width;
                grid-template-columns: repeat(4, auto);
                grid-template-rows: 1fr;
                width: 100% !important;
                margin: 0 auto !important;
            }

            .pagebuilder-column {
                margin: 0 $spacer--medium $spacer--large;
                width: auto !important;

                @include mq($screen-m) {
                    margin: 0 !important;
                    width: 100% !important;
                }

                &:first-child {
                    @include mq($screen-m) {
                        grid-row: 1;
                        grid-column: 1 / 4;
                    }

                    @include mq($screen-xl) {
                        grid-row: auto;
                        grid-column: auto;
                    }
                }

                &:last-child {
                    margin-bottom: 0 !important;
                }

                h3 {
                    @include mq($screen-m) {
                        font-size: $font-size-large;
                    }
                }
            }
        }

        .content-with-bg {
            &__heading {
                margin: 0 0 $spacer 0;
                font-size: 40px;
                line-height: 1;
            }

            &__text {
                margin: 0 0 $spacer--semi-medium 0;
            }
        }
    }

    &--pdp {
        padding-top: $spacer--extra-large;
        background-size: auto 55% !important;

        @include mq($screen-m) {
            padding-top: 56px;
            padding-bottom: 84px;
        }

        @include mq($screen-l) {
            background-size: auto 60% !important;
        }

        @include mq($screen-xl) {
            background-size: auto clamp(100px, 75%, 452px) !important;
        }

        .pagebuilder-column-group {
            .pagebuilder-column {
                margin: 0 $spacer--medium;
                max-width: 830px;

                @include mq($screen-l) {
                    margin: 0 auto 0 $spacer--semi-large;
                }

                @include mq($screen-xl) {
                    margin: 0 auto 0 0;
                }
            }

            .content-with-bg {
                &__heading {
                    margin: 0 0 $spacer--semi-medium 0;
                    font-size: $font-size-extra-large-2;

                    @include mq($screen-m) {
                        font-size: 50px;
                    }
                }

                &__text {
                    margin: 0 0 $spacer--large 0;
                }
            }
        }
    }

    &--download {
        .content-with-bg__heading {
            font-size: $font-size-extra-large-2;


            @include mq($screen-m) {
                font-size: 50px;
            }
        }
    }
}
