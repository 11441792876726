.dashboard-form {
    .input {
        margin-bottom: $spacer--semi-medium;
    }

    .checkbox {
        margin: 0 0 $spacer--semi-medium $spacer--small;

        &__label {
            padding: 0 0 0 $spacer--large;
        }
    }

    .actions-group__handler {
        display: flex;
        justify-content: center;
        margin: 0;
    }

    .form-edit-account,
    &.manage-payment-method {
        @include mq($screen-l) {
            max-width: $account-form__fieldset-max-width\@medium;
        }

        .fieldset .field .input-text {
            width: 100%;
        }
    }
}
