$footer__background-color: $navy;

$footer__newsletter-border-width: 0 0 2px 0;
$footer__newsletter-border: 2px $border-style-base $white;
$footer__newsletter-padding\@large: 0 $spacer--large $spacer--extra-large 0;

$footer__link-color: $white;
$footer__link-color-hover: $white;
$footer__link-font-size: $font-size-large;

$footer__links-label-color: $white;
$footer__links-padding\@large: 18px 0 0;
$footer__links-label-background: transparent;
$footer__links-dropdown-background: transparent;
$footer__links-dropdown-list-padding\@large: 0;
$footer__links-dropdown-list-padding\@extra-large: 0;
$footer__links-dropdown-list-border: $footer__newsletter-border;
$footer__links-dropdown-list-border-width: $footer__newsletter-border-width;
$footer__links-dropdown-list-item-border: $footer__newsletter-border;
$footer__links-dropdown-list-item-border-width: $footer__newsletter-border-width;
$footer__links-dropdown-icon-color: $white;
$footer__links-dropdown-icon-color-open: $white;
$footer__links-dropdown-icon-color-hover: $white;

$footer__social-list-title-color: $white;
$footer__social-list-title-margin: 0 0 $spacer--semi-medium 0;
$footer__social-list-title-margin\@medium: 0 $spacer--semi-medium 0 0;
$footer__social-list-title-margin\@large : 0 0 $spacer--semi-medium 0;
$footer__social-handler-before-width\@large: 2px;

$footer__handler-padding: $spacer--extra-large $spacer $spacer--semi-medium;
$footer__handler-padding\@medium: $spacer--extra-large $spacer--medium;
$footer__handler-padding\@large: 56px 0;

$footer__bottom-bar-background: $gray-darkest;
$footer__bottom-flex-direction: column-reverse;
$footer__bottom-padding\@large: 0;
$footer__bottom-align: flex-start;

$footer__payments-list-margin: $spacer--large 0 $spacer--semi-medium;
$footer__payments-list-justify-content: flex-start;

$footer__copywrite-order: 0;
$footer__copywrite-padding: 0 $spacer $spacer--large;
$footer__copywrite-padding\@medium: 0 $spacer--medium;
