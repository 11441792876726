.forgot-password {
    .actions-group__link {
        color: $color-primary;

        &:hover,
        &:focus {
            color: $gray-dark;
        }
    }

    &__captcha-wrapper {
        justify-content: space-between !important; // sass-lint:disable-line no-important
    }
}
