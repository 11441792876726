// sass-lint:disable no-misspelled-properties no-important
div[data-content-type='slider'] {
    &.slider-testimonials {
        margin-bottom: 110px !important;
        margin-top: $spacer--extra-large;

        @include mq($screen-l) {
            margin-top: 110px;
        }

        @include mq($screen-xl) {
            margin-bottom: 220px !important;
        }

        .slick-dots {
            bottom: -64px;

            @include mq($screen-xl) {
                bottom: -128px;
            }

            li {
                display: inline-flex;
                align-items: center;
                margin: 0 $spacer;
                width: 20px;
                height: 20px;

                button {
                    width: 16px;
                    height: 16px;
                    background: #e2e5ec;
                }

                &.slick-active {
                    button {
                        width: 20px;
                        height: 20px;
                        background-color: $blue;
                        border: 1px solid $blue;
                    }
                }
            }
        }

        .pagebuilder-overlay {
            max-width: 606px;
            margin: 0 auto;
            padding: 0;

            @include mq($screen-xl) {
                max-width: $max-content-width;
            }
        }

        [data-element="content"] {
            margin: 0 $spacer;

            @include mq($screen-m) {
                margin: 0;
            }
        }
    }

    .slider-testimonials {
        &__image {
            height: auto !important;
            margin: 0 auto;

            @include mq($screen-m) {
                max-width: 556px;
            }

            @include mq($screen-l) {
                margin: 0 auto 84px;
            }

            @include mq($screen-xl) {
                margin: 0;
            }
        }

        &__description {
            margin: 0 0 $spacer--large 0;
            color: $color-primary;
            font-weight: $font-weight-medium;
            font-size: 22px;

            @include mq($screen-l) {
                font-size: 26px;
            }
        }

        &__name {
            margin: 0 0 $spacer 0;
            color: $blue;
            font-weight: $font-weight-bold;
            font-size: 20px;
            letter-spacing: 0.69px;
        }

        &__job-position {
            margin: 0;
            font-size: $font-size-large;
        }

        &__content {
            @include mq($screen-xl) {
                display: flex;
                gap: 110px;
            }

            p:first-child {
                @include mq($screen-xl) {
                    flex-shrink: 0;
                }
            }
        }

        &__text-content {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
}

.slider-testimonials-row {
    background-size: 0 !important;

    @include mq($screen-m) {
        background-size: auto 250px !important;
    }

    @include mq($screen-l) {
        background-size: auto 403px !important;
    }

    @include mq($screen-xl) {
        background-size: auto 421px !important;
    }
}
