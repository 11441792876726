// sass-lint:disable no-misspelled-properties, no-important
.content-with-images {
    padding: $spacer--extra-large 0;

    @include mq($screen-m) {
        padding: 84px 0;
    }

    @include mq($screen-xl) {
        flex-direction: row !important;
        gap: $spacer--extra-large;
    }

    &__button {
        div {
            height: 56px;
        }
    }

    .pagebuilder-column-group {
        @include mq($screen-xl) {
            display: grid;
            grid-template-columns: 1fr 2fr;
            column-gap: $spacer--extra-large;
        }
    }

    .pagebuilder-column-line {
        // heading + button
        &:first-child {
            @include mq($screen-m) {
                padding: 0 $spacer--semi-large 0;
            }

            @include mq($screen-xl) {
                flex-basis: 100%;
                padding: 0;
            }

            .pagebuilder-column {
                > * {
                    @include mq($screen-xl) {
                        text-align: left !important;
                    }
                }
            }
        }

        // images + desc
        &:last-child {
            flex-direction: column;
            padding: 60px 0 0 0;

            @include mq($screen-m) {
                flex-direction: row;
                gap: $spacer--semi-medium;
                padding: 60px $spacer--semi-large 0;
            }

            @include mq($screen-xl) {
                gap: $spacer--large;
                padding: 0;
            }

            .pagebuilder-column {
                margin: 0 0 $spacer--extra-large 0;
                min-width: 100%;

                @include mq($screen-m) {
                    margin: 0;
                    min-width: auto;
                }

                &:last-child {
                    margin: 0;
                }

                figure {
                    width: calc(100vw + #{$spacer--extra-small}) !important;
                    margin-left: calc(50% - 50vw) !important;
                    margin-bottom: $spacer--large;
                    margin-top: 0;

                    @include mq($screen-m) {
                        width: auto !important;
                        margin: 0 0 $spacer--large 0 !important;
                    }

                    img {
                        width: 100%;
                    }
                }

                div > p {
                    font-size: $font-size-large;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                h2 {
                    font-size: $font-size-large-2;

                    @include mq($screen-m) {
                        font-size: $font-size-extra-large;
                        margin-bottom: $spacer--medium;
                    }

                    @include mq($screen-l) {
                        font-size: 28px;
                        font-weight: $font-weight-medium;
                    }
                }
            }
        }
    }
}
