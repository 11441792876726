.minicart-product {
    flex-flow: row wrap;

    &__item {
        width: 100%;
    }

    &__attributes-list {
        display: none;
    }

    &__button.delete {
        .minicart-product__button-icon {
            fill: $gray-dark;
        }
    }

    &__image {
        @include mq($screen-m) {
            flex-basis: 120px;
        }
    }

    &__data {
        padding-left: $spacer--large;
    }

    &__input {
        margin-right: $spacer;

        @include mq($screen-m) {
            margin-right: $spacer--medium;
        }
    }
}
