@import "cart-variables";

.cart-discount {
    flex-basis: 100%;
    margin: $cart-discount__margin;
    padding: $cart-discount__padding;

    &.active {
        .cart-discount__button-icon {
            transform: $cart-discount__button-icon-transform;
        }

        .cart-discount__content {
            display: block;
        }
    }

    &__button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: $cart-discount__button-padding;
        border: $cart-discount__button-border;
        border-width: $cart-discount__button-border-width;
        background: $cart-discount__button-background;
        font-family: $cart-discount__button-font-family;
        text-transform: $cart-discount__button-text-transform;
        cursor: pointer;

        &:hover {
            background: $cart-discount__button-background--hover;

            &:before,
            &:after {
                display: none;
            }
        }
    }

    &__button-text {
        margin: $cart-discount__button-text-margin;
        font-size: $cart-discount__button-text-font-size;
        color: $cart-discount__button-text-color;
        font-weight: $cart-discount__button-text-font-weight;
    }

    &__button-icon {
        transition: $cart-discount__button-icon-transition;
    }

    &__content {
        display: none;
        padding: $spacer--semi-medium 0;
    }

    &__form {
        display: block;
    }

    &__controls {
        display: flex;
        align-items: flex-start;
        margin-bottom: $cart-discount__controls-margin-bottom;
        padding: $cart-discount__controls-padding;
    }

    &__content-input {
        width: 70%;
        margin: $cart-discount__input-margin;
    }

    &__content-button {
        width: 30%;
        min-width: $cart-discount__button-min-width;
    }
}
