$search-no-results__icon-margin: 72px 0 $spacer--large;
$search-no-results__description-line-height: 2;
$search-no-results__description-margin-bottom: 0;

.search-no-results {
    .heading--page {
        margin: 78px 0 0;

        @include mq($screen-l) {
            margin: 118px 0 0;
        }
    }

    &__description {
        .paragraph {
            line-height: 2;
        }
    }

    &__slider {
        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            margin: 84px 0;
            background-color: $gray;
        }
    }
}
