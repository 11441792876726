// sass-lint:disable no-misspelled-properties no-important

.logos-slider {
    padding: $spacer--extra-large 0;

    @include mq($screen-m) {
        padding: 84px 0;
    }

    .slider__heading {
        text-align: center;
        margin-bottom: $spacer--extra-large;

        @include mq($screen-m) {
            margin-bottom: 84px;
        }
    }

    .slider__nav-button {
        border: none;
        background: transparent;

        &:before {
            background: transparent;
        }
    }

    .slider__nav-icon.icon {
        fill: $color-primary;
        width: 28px;
        height: 28px;
    }

    .slick-track {
        gap: $spacer--semi-medium;

        @include mq($screen-m) {
            gap: 84px;
        }
    }

    .slick-slide {
        .banner {
            display: flex;
            align-items: center;

            img {
                max-height: 125px;
                margin: 0 auto;
                object-fit: contain;
            }
        }
    }
}
