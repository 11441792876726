.price {
    color: $color-primary;

    &__value {
        &--old {
            .price {
                color: $price__color--old;
            }
        }

        &--special {
            .price {
                color: $price__color--special;
            }
        }
    }
}

.catalog-grid,
.catalog-list {
    .price {
        display: flex;
    }

    .minimal-price {
        display: flex;
        align-items: center;

        .price__text {
            margin-right: $spacer;
        }

        .price__value {
            font-size: $font-size-medium;
        }
    }

    .price__value--old {
        margin-right: $spacer;
    }

}
