.wishlist {
    &__rating-wrapper {
        display: none;
    }

    &__item {
        margin-bottom: $spacer--medium;

        @include mq($screen-xl) {
            margin-bottom: $spacer--semi-medium;
        }

        .price-box {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__item-inner {
        padding-bottom: $spacer--medium;

        @include mq($screen-l) {
            padding-bottom: 0;
        }
    }

    &__comment {
        @include mq($screen-xl) {
            padding: 0 $spacer;
        }

        .input__field {
            color: $gray-darker;
            border: $border-base;
            resize: none;
        }
    }

    &__qty {
        flex-direction: row;
        align-items: center;

        @include mq($screen-l) {
            flex-direction: column;
            align-items: flex-start;
            width: auto;
        }

        @include mq($screen-xl) {
            margin-left: $spacer;
        }

        .quantity-update {
            &__input {
                @include mq($max-content-width) {
                    border-width: $border-width-base 0;
                }
            }
        }
    }

    &__actions {
        border-top: $border-base;

        .link {
            font-size: $font-size-base;
            text-decoration: none;
            letter-spacing: normal;
        }
    }

    &__item-actions {
        width: 100%;
        flex: 1;

        @include mq($screen-l) {
            width: auto;
        }

        @include mq($screen-xl) {
            margin-right: $spacer;
        }

        &--to-cart {
            flex: none;
            width: 100%;
        }
    }

    &__button {
        padding: $spacer 0;
    }

    &__button-edit {
        .button__icon.icon {
            fill: $navy;
        }
    }

    &__label {
        @include mq($screen-l) {
            padding: 0 0 $spacer 0;
        }
    }

    &__grid {
        @include mq($screen-l) {
            padding: 0 0 $spacer--semi-large;
        }
    }

    .actions-group__handler {
        margin: 0;
    }
}
