.filters {
    &__heading {
        text-transform: uppercase;

        &#filters-option-title {
            @include mq($screen-l) {
                display: none;
            }
        }
    }
}
