.dashboard-nav {
    &__mobile {
        color: $navy;

        &:after {
            border-color: transparent $navy $navy transparent;
        }
    }

    &__logout-button--mobile {
        margin-top: 96px;
    }

    &__section-title {
        text-transform: uppercase;
    }
}
