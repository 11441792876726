.catalog-list__products {
    .results {
        .block {
            margin: 20px 0;

            dt {
                font-weight: bold;
                padding-right: 20px;
                display: inline-block;
                font-size: 16px;
            }
            dd.item {
                display: inline-block;
                padding-right: 20px;
                font-style: italic;
                font-size: 14px;
            }
        }
    }
}
