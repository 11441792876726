.contact-info {
    padding: $spacer--semi-medium;

    @include mq($screen-l) {
        padding: $spacer--semi-medium $spacer--semi-large;
    }

    &--mobile-hidden {
        display: none;

        @include mq($screen-m) {
            display: block;
        }
    }

    &__heading {
        font-size: $font-size-large-2;

        @include mq($screen-m) {
            margin-bottom: 0 !important;
        }
    }

    &__content {
        flex-direction: row !important;
        align-items: center;

        @include mq($screen-m) {
            justify-content: flex-end !important;
        }
    }

    &__text {
        padding-right: $spacer--semi-medium;
        margin-bottom: 0;

        p {
            margin: auto 0;
        }

        a {
            font-weight: 700;
            color: $blue;
            font-size: $font-size-medium;

            @include mq($screen-xl) {
                font-size: $font-size-large-2;
            }
        }
    }

    .pagebuilder-column-line {
        max-width: $max-content-width;
        margin: 0 auto;
        width: 100%;
        flex-direction: column;

        @include mq($screen-m) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .pagebuilder-column {
        width: auto !important;
        justify-content: center !important;
    }
}
