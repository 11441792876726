$cms-page__max-width: 100%;

$not-found__padding: 64px 0 0;

$not-found__heading-font-weight: $font-weight-bold;
$not-found__heading-margin: 0 0 76px;

$not-found__number-font-weight: $font-weight-bold;

$not-found__actions-margin: 72px 0;
$not-found__actions-margin\@large: $not-found__actions-margin;
