.auth {
    @include mq($screen-m) {
        margin: 0 $spacer--large;
    }

    &__heading {
        max-width: 55%;
        font-size: $font-size-extra-large;
        line-height: 48px;
        letter-spacing: 0.75px;

        @include mq($screen-m) {
            max-width: none;
            font-size: $font-size-super-extra-large;
            letter-spacing: 1px;
        }
    }

    &__link {
        padding: 0 $spacer--large 0 $spacer--small;
    }

    &__icon {
        filter: brightness(0);
    }

    &__links {
        :last-child {
            border-bottom: none;

            @include mq($screen-m) {
                border-bottom: $border-base;
            }
        }
    }
}
