@import 'product-view-variables';

// container
$product-view__padding: $spacer--extra-large 0 0 0;
$product-view__padding\@medium: 84px 0 0 0;
$product-view__padding\@xl: 84px 0 0 0;;

// wrapper / flex & grid
$product-view__column-gap\@large: $spacer--semi-large;
$product-view__column-gap\@xl: 98px;
$product-view__details-grid-row-start\@medium: 1;

// title
$product-view__title-font-size: $font-size-extra-large-2;
$product-view__title-margin: 0 0 $spacer--medium;

//form
$product-view__form-border-width: 0;
$product-view__form-padding: $spacer--medium 0 0 0;

// stock
$product-view__stock-color: $navy;
$product-view__stock-min-height: auto;
$product-view__stock-text-decoration-hover: none;

// gallery
$product-view__gallery-margin: 0 0 $spacer--extra-large;

// other
$product-view__button-wishlist-background-color: $white;
$product-view__rating-padding: 0 0 $spacer--medium;
$product-view__sku-display: block;
$product-view__stock-text-decoration-hover: none;
$product-view__add-to-cart-ee-action-bottom: 0;
$product-view__add-to-cart-ee-action-right: 0;
$product-view__slider-margin: 0;
$product-view__qty-margin: 0;
