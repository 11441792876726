.heading {
    &--fourth-level,
    &--fifth-level,
    &--sixth-level {
        font-weight: $font-weight-medium;
    }

    &--margin-secondary {
        margin-bottom: $spacer--semi-large;

        @include mq($screen-m) {
            margin-bottom: 84px;
        }
    }

    &--page {
        @include mq($screen-m) {
            margin: $headings-margin--page\@medium;
        }

        &.heading--page-full-width {
            @include mq($screen-m) {
                margin: $heading-margin--page-full-width\@medium;
            }
        }
    }
}
