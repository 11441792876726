.slider-article-item {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__picture {
        .ratio-image {
            object-fit: cover;
            height: 100%;
        }
    }

    &__details {
        display: flex;
        flex-direction: column;
        flex: 1;
        background: $white;
        padding: $spacer--semi-medium $spacer--medium $spacer--medium;

        @include mq($screen-xl) {
            padding: $spacer--large;
        }
    }

    &__title {
        margin-bottom: $spacer--semi-medium;

        @include mq($screen-xl) {
            margin-bottom: $spacer--large;
        }
    }

    &__link {
        margin-top: auto;
        text-decoration: underline;
    }
}
