.banner-list {
    padding: $spacer--extra-large 0;

    @include mq($screen-m) {
        padding: 84px 0;
    }

    &__banner {
        margin: 0;
        height: 100%;

        a {
            height: 100%;

            picture {
                height: 100%;

                img {
                    height: 100% !important; // sass-lint:disable-line no-important
                }
            }
        }
    }

    .pagebuilder-column-line {
        flex-direction: column;
        gap: $spacer--semi-medium; // sass-lint:disable-line no-misspelled-properties

        @include mq($screen-m) {
            flex-direction: row;
        }

        .pagebuilder-column {
            min-width: 100%;

            @include mq($screen-m) {
                min-width: auto;
            }
        }
    }
}
