@mixin cms-page-content() {
    max-width: none;

    @include mq($screen-xl) {
        max-width: $max-content-width;
    }
}

.cms-page {
    &--pb {
        margin: 0 $spacer $spacer--extra-large;

        @include mq($screen-m) {
            margin: 0 $spacer--semi-medium 84px;
        }

        @include mq($screen-l) {
            margin: 0 $spacer--semi-large 84px;
        }

        @include mq($screen-xl) {
            margin: 0 auto 110px;
        }

        .cms-page__content {
            @include cms-page-content();
        }
    }

    &--pb-secondary {
        margin: 56px $spacer 84px;

        @include mq($screen-m) {
            margin: 56px $spacer--semi-medium 84px;
        }

        @include mq($screen-l) {
            margin: 56px $spacer--semi-large 110px;
        }

        @include mq($screen-xl) {
            margin: 56px auto 110px;
        }

        .cms-page__content {
            @include cms-page-content();
        }
    }

    &--full-width {
        margin: 0;

        .cms-page__content {
            max-width: none;
        }
    }

    &--product-page {
        margin: 0;

        .cms-page__content {
            @include cms-page-content();
        }
    }
}

.page-layout-1-column-cms-full-width-breadcrumbs {
    .cms-page--full-width-breadcrumbs {
        margin: calc(-#{$breadcrumbs__min-height} - 2px) 0 0 0;

        .cms-page__content {
            max-width: none;
        }
    }

    .page-wrapper {
        margin-bottom: 0;
    }
}

// Breadcrumbs on top of first element
.page-layout-1-column-cms-full-width-breadcrumbs-within {
    .breadcrumbs__link {
        z-index: 1;
        color: $white;
    }

    .breadcrumbs__item {
        z-index: 1;
        color: $white;

        &:last-child:not(:first-child) {
            color: $white;
        }
        &::after {
            color: $white;
        }
    }

    .cms-page--full-width-breadcrumbs-within {
        margin: calc(-#{$breadcrumbs__min-height} - 2px) 0 0 0;

        .cms-page__content {
            max-width: none;
        }
    }

    .page-wrapper {
        margin-bottom: 0;
    }
}

.page-layout-1-column-cms-product-page {
    .page-wrapper {
        margin-bottom: 0;
    }
}
