@import "cart-list-item-variables";

.cart-list-item {
    padding: $cart-list-item__padding;
    border-bottom: $cart-list-item__border-bottom;

    @include mq($screen-m) {
        display: flex;
        flex-flow: row wrap;
    }

    &__left {
        display: flex;

        @include mq($screen-m) {
            flex-basis: 50%;
        }

        @include mq($screen-xl) {
            flex-basis: 60%;
        }
    }

    &__right {
        margin: $cart-list-item__right-margin;
        padding: $cart-list-item__right-padding;

        @include mq($screen-m) {
            display: flex;
            flex-basis: 50%;
            flex-wrap: wrap;
            align-content: space-between;
            margin: $cart-list-item__right-margin\@medium;
            padding: $cart-list-item__right-padding\@medium;
        }

        @include mq($screen-xl) {
            flex-basis: 40%;
        }
    }

    &__image {
        width: $cart-list-item__image-width;

        @include mq($screen-m) {
            width: $cart-list-item__image-width\@medium;
        }

        @include mq($screen-xl) {
            width: $cart-list-item__image-width\@x-large;
        }
    }

    &__content {
        flex-basis: $cart-list-item__content-flex-basis;
        padding: $cart-list-item__content-padding;

        @include mq($screen-m) {
            flex-basis: $cart-list-item__content-flex-basis\@medium;
        }

        @include mq($screen-xl) {
            flex-basis: $cart-list-item__content-flex-basis\@x-large;
            padding: $cart-list-item__content-padding\@x-large;
        }
    }

    &__name {
        display: block;
        font-family: $cart-list-item__name-font-family;
        font-size: $cart-list-item__name-font-size;
        font-weight: $cart-list-item__name-font-weight;
        line-height: $cart-list-item__name-line-height;
        text-transform: $cart-list-item__name-text-transform;
    }

    &__attribute {
        display: block;
        width: 100%;
        color: $cart-list-item__attribute-color;
    }

    &__label,
    &__price {
        color: $cart-list-item__label-color;
        line-height: $cart-list-item__label-line-height;
    }

    &__price {
        @include mq($screen-m) {
            font-size: $cart-list-item__price-font-size\@medium;
        }
    }

    &__label {
        @include mq($screen-m) {
            @include visually-hidden;
        }
    }

    &__details {
        @include mq($screen-m) {
            display: flex;
            flex-basis: 100%;
            align-items: flex-start;
        }
    }

    &__wrapper {
        @include mq($screen-m) {
            flex-basis: 40%;
        }

        @include mq($screen-xl) {
            flex-basis: 35%;
        }
    }

    &__qty {
        margin: $cart-list-item__qty-margin;

        @include mq($screen-m) {
            flex-direction: $cart-list-item__qty-flex-direction\@medium;
            flex-basis: 20%;
        }

        @include mq($screen-xl) {
            flex-basis: 30%;
            padding: $cart-list-item__qty-padding\@x-large;
        }
    }

    &__input {
        width: $cart-list-item__input-width;
        height: $cart-list-item__input-height;
        margin: $cart-list-item__input-margin;
        appearance: textfield;

        @include mq($screen-xl) {
            width: 100%;
        }

        &::-webkit-inner-spin-button, // sass-lint:disable-line no-vendor-prefixes
        &::-webkit-outer-spin-button { // sass-lint:disable-line no-vendor-prefixes
            appearance: none;
            margin: 0;
        }
    }

    &__total {
        font-size: $cart-list-item__total-font-size;
        line-height: $cart-list-item__total-line-height;
        font-weight: $cart-list-item__total-font-weight;
    }

    &__actions {
        display: $cart-list-item__actions-display;
        flex-flow: row wrap;

        @include mq($screen-m) {
            display: $cart-list-item__actions-display\@medium;
            flex-basis: 100%;
            justify-content: flex-end;
        }
    }

    &__action {
        margin-right: $cart-list-item__action-margin-right;

        &:last-child {
            margin-right: 0;
        }
    }

    &__attributes-list {
        @extend .minicart-product__attributes-list;
    }

    &__gift-dropdown {
        width: 100%;

        @include mq($screen-m) {
            text-align: right;
        }
        .dropdown-list {
            &__content {
                display: none;
                &._active {
                    display: block;
                }
            }

            &__label {
                @include mq($screen-m) {
                    margin: $cart-list-item__gift-dropdown-label-margin\@medium;
                }

                &._active {
                    .dropdown-list__icon {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }

    &__alert {
        @include visually-hidden();
    }
}
