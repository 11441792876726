.order-approve-manage {
    .order-approve-manage-items-table {
        .cart {
            &.item {
                .col {
                    border-bottom: $border-width-base $border-style-base $border-color-base;
                }
            }
        }
    }
}

.product-item {
    &-wrapper {
        display: flex;
    }

    &-thumbnail {
        width: 25px;
        margin-right: 10px;
    }

    &-photo {
        padding-right: 0 !important;
    }
}
