$minicart-content__padding: 0;
$minicart-content__heading-font-weight: $font-weight-bold;
$minicart-content__products-list-padding: 0;
$minicart-content__action-button-width: 100%;
$minicart-content__summary-label-text-transform: default;
$minicart-content__summary-label-color: $gray-darker;
$minicart-content__bottom-box-shadow: none;
$minicart-content__bottom-padding: 0;
$minicart-content__summary-margin: $spacer--semi-large 0;
$minicart-content__extra-actions-flex: 0;
$minicart-content__middle-margin: 0;
$minicart-content__middle-padding: $spacer--semi-medium 0 0;
