.product-item {
    .tooltip {
        position: relative;

        &--is-absolute {
            position: absolute;
            top: 0;
            right: 0;
        }

        &--align-right {
            .tooltip__content-wrapper {
                transform: translate(-100%, -100%);
                left: 100%;
            }
        }

        &:after {
            transform: rotate(45deg) translate(-#{$spacer}, -#{$spacer});
            right: $spacer--small;
        }

        &__content {
            font-size: $font-size-base;
        }

        &__content-wrapper {
            min-width: 144px;
            top: -#{$spacer};
            left: 0;
            transform: translateY(-100%);
            padding: $spacer $spacer--medium;
        }
    }
}

.product-list-item {
    .tooltip:after {
        right: $spacer--small;
    }

    .tooltip--align-right {
        .tooltip__content-wrapper {
            left: calc(100% - #{$spacer});
        }
    }

}

