.blog {
    &__articles {
        padding: 0 $spacer--medium;

        @include mq($screen-l) {
            margin: 84px 0 56px 0;
            padding: 0 $spacer--extra-small;
        }

        @include mq($screen-xl) {
            flex-basis: 71%;
            max-width: 71%;
        }

        .pager {
            margin: 0;

            &__link {
                color: $color-primary;

                &--prev {
                    @include mq($screen-l) {
                        margin-right: $spacer--large;
                    }
                }

                &--next {
                    @include mq($screen-l) {
                        margin-left: $spacer--large;
                    }
                }
            }

            &__list {
                padding: 0;
            }

            &__item {
                margin: 0;

                @include mq($screen-xl) {
                    margin: 0 $spacer--extra-small;
                }

                svg {
                    fill: $gray;
                }
            }

            &:first-child {
                margin: 0;
            }
        }
    }

    &__articles-list {
        @include mq($screen-l) {
            padding: 0 $spacer--extra-small;
        }
    }

    &__nav {
        padding: 0 $spacer--medium;
        margin-bottom: $spacer--semi-medium;

        @include mq($screen-m) {
            margin: $spacer--extra-large 0 0 0;
        }

        @include mq($screen-l) {
            padding: 0 $spacer;
            margin: 84px 0 0 0;
        }
    }
}
