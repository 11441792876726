.add-to-cart {
    .button:not(:hover):after {
        display: none;
    }

    &__action-secondary {
        bottom: $add-to-cart__min-height + $spacer--small;

        @include mq($screen-m) {
            bottom: inherit;
        }

        .button {
            background-color: inherit;
        }
    }

    &__button-more {
        &:not(:active) {
            &:before {
                transform: scaleX(0);
            }

            &[aria-expanded="true"]:before {
                transform: scaleX(1);
            }
        }
    }

    .button--dowload {
        padding: 0 $spacer;
        @include mq($screen-m) {
            min-width: 300px;
            height: 56px;
            padding: 0 $spacer--large;
        }

        .button__icon {
            fill: $white;
            @include mq($screen-m) {
                display: none;
            }
        }

        .button__text {
            display: none;

            @include mq($screen-m) {
                display: block;
            }
        }
    }
}
