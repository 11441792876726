.product-list-item {
    &__name-link {
        color: $color-primary;
    }

    &__actions-inner {
        .button--icon {
            background-color: $gray-lighter;
        }
    }

    &__list ul {
        padding-left: $spacer--medium;
        list-style: square;
    }
}
