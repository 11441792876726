.toolbar {
    &__sorter-label,
    &__limiter-label {
        font-size: $font-size-medium;
    }

    &__sorter .select2-selection__arrow b,
    &__limiter .select2-selection__arrow b {
        padding: 3px !important; // sass-lint:disable-line no-important
    }
}
