.career-hero {
    padding: $spacer--extra-large $spacer--medium 74px;
    border-bottom: $border-base;
    background: url('../images/wheel_half.png') no-repeat;
    background-size: auto clamp(100px, 310px, 90%);
    background-position: bottom 0 left 0;

    @include full-width();

    @include mq($screen-m) {
        padding: 56px $spacer--semi-large 78px;
        background-position: bottom 0 right -400px;
    }

    @include mq($screen-l) {
        padding: 56px $spacer--semi-large 84px;
        background-position: bottom 0 right -550px;
    }

    @include mq($screen-xl) {
        padding: 56px 0 84px;
        background-position: bottom 0 right 10%;
    }

    &--offer {
        padding: $spacer--extra-large $spacer--medium;

        @include mq($screen-m) {
            padding: 56px $spacer--semi-large 68px;
        }

        @include mq($screen-l) {
            padding: 56px $spacer--semi-large 68px;
        }

        @include mq($screen-xl) {
            padding: 56px 0 68px;
        }

        .career-hero__heading {
            margin: 0 0 $spacer--extra-large 0;

            @include mq($screen-m) {
                margin: 0 0 68px 0;
            }
        }
    }

    &__content {
        max-width: $max-content-width;
        margin: 0 auto;
    }

    &__heading {
        font-size: $font-size-extra-large-2;
        margin: 0 0 $spacer--semi-medium 0;

        @include mq($screen-m) {
            font-size: 50px;
            margin: 0 0 $spacer--large 0;
            letter-spacing: 1.88px;
        }
    }

    &__description {
        font-size: $font-size-medium;
        max-width: 640px;

        @include mq($screen-m) {
            font-size: $font-size-large-2;
        }
    }

    &__type {
        font-size: $font-size-large-2;
        color: $blue;
        margin: 0 0 $spacer 0;
    }

    &__info {
        font-size: $font-size-large;
        margin: 0;
    }

    &__button {
        width: 100%;
        height: 56px;
        min-width: 196px;

        @include mq($screen-m) {
            width: auto;
        }
    }

    &__details {
        @include mq($screen-m) {
            display: grid;
            grid-template-rows: repeat(2, auto);
            grid-template-columns: repeat(3, 1fr);
            row-gap: $spacer--semi-large; //sass-lint:disable-line no-misspelled-properties
            column-gap: 70px;
        }

        @include mq($screen-l) {
            grid-template-columns: repeat(4, 1fr);
            column-gap: $spacer--semi-large;
        }

        @include mq($screen-xl) {
            grid-template-columns: repeat(4, 1fr) auto;
            grid-template-rows: 1fr;
        }
    }

    &__item {
        margin: 0 0 $spacer--semi-large 0;

        @include mq($screen-m) {
            margin: 0;
        }

        &--link {
            @include mq($screen-m) {
                grid-column: 2 / 4;
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }

            @include mq($screen-l) {
                grid-column: 4 / 5;
                grid-row: 2;
            }

            @include mq($screen-xl) {
                grid-column: auto;
                grid-row: auto;
                justify-content: center;
            }
        }


        &:nth-last-child(2) {
            @include mq($screen-l) {
                grid-row: 2;
            }

            @include mq($screen-xl) {
                grid-row: auto;
            }
        }

        &:last-child {
            margin: 0;
        }
    }
}
