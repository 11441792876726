.actions-toolbar {
    @include mq($screen-m) {
        width: 50%;
        display: flex;
        flex-direction: row;
    }

    .action {
        &.back {
            color: $font-color-base;
        }
    }
}
