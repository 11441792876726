.section {
    &__heading {
        &--products {
            .heading {
                @include mq($screen-m) {
                    font-size: $font-size-super-extra-large;
                }
            }
        }
    }
}
