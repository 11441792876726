.dashboard-items {
    &__title {
        margin-bottom: $spacer--large;

        &--edit-account {
            background-color: transparent;
            text-transform: uppercase;
            padding-left: 0;
        }
    }

    &__subtitle {
        margin-bottom: $spacer--large;
        color: $navy;
    }
}
