.account-form {
    margin-bottom: 56px;

    &__info {
        line-height: 32px;
        padding: 0 0 $spacer--large;

        @include mq($screen-m) {
            padding: 0;
        }
    }

    &__newsletter-checkbox {
        margin: 0 0 0 $spacer--small;
    }

    &__form {
        fieldset:last-of-type {
            margin: 0 0 $spacer--large;
        }
    }

    &__fieldset {
        .input {
            margin-bottom: $spacer--semi-medium;

            &--password {
                margin-bottom: $spacer--medium;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        .button--icon:before {
            top: 1px;
            bottom: 1px;
        }

    }

    &__validation-tips {
        line-height: 32px;
        margin-bottom: 0;
    }

    &__legend {
        padding: 0 0 $spacer--semi-medium 0;
        color: $color-primary;
    }

    &__button {
        font-weight: $font-weight-medium;
    }

    .required {
        label:not(.checkbox__label):after {
            color: $color-secondary;
        }
    }
}
