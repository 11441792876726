//sass-lint:disable no-important
.content-with-image {
    padding: $spacer--extra-large 0;

    @include mq($screen-l) {
        padding: 84px 0;
    }

    @include mq($screen-xl) {
        padding: 110px 0;
    }


    &__image-column {
        @include mq($screen-m) {
            width: 100% !important;
        }

        @include mq($screen-l) {
            justify-content: center !important;
        }
    }

    &__image-container {
        margin: 0 $spacer $spacer--semi-large;

        @include mq($screen-l) {
            margin: 0 $spacer--semi-medium $spacer--extra-large;
        }

        @include mq($screen-xl) {
            margin: 0 $spacer--semi-medium;
        }

        img {
            width: 100%;
        }
    }

    &__content {
        margin: 0 $spacer;
        order: 1;

        @include mq($screen-m) {
            margin: 0 $spacer--semi-medium;
            width: 100% !important;
        }

        @include mq($screen-l) {
            margin: 0 $spacer--semi-large;
            justify-content: center !important;
        }

        @include mq($screen-xl) {
            max-width: 584px;
            order: 0;
        }
    }

    &__text {
        margin: 0 0 $spacer--large 0;

        @include mq($screen-xl) {
            max-width: 570px;
        }

        p {
            margin: 0 0 $spacer--extra-large;
            font-size: $font-size-large;

            &:last-of-type {
                margin: 0;
            }
        }

        li {
            padding: 0 0 $spacer;
        }
    }

    &__buttons {
        .pagebuilder-button-primary {
            min-width: 300px;

            @include mq($screen-m) {
                min-width: 250px;
            }
        }
    }

    .pagebuilder-column-line {
        flex-direction: column;

        @include mq($screen-xl) {
            flex-wrap: nowrap;
            flex-direction: row-reverse;
            max-width: $max-content-width;
            margin: 0 auto;
        }
    }

    .pagebuilder-column {
        width: auto !important;

        @include mq($screen-xl) {
            width: 50% !important;
        }
    }

    &--content-wrap-at-screen-l {
        .content-with-image {
            &__buttons {
                width: 100%;

                [data-content-type="button-item"] {
                    width: 100%;
                }

                @include mq($screen-m) {
                    width: auto;

                    [data-content-type="button-item"] {
                        width: auto;
                    }
                }

                .pagebuilder-button-primary {
                    width: 100%;
                    min-width: 300px;

                    @include mq($screen-m) {
                        width: auto;
                        min-width: 250px;
                    }
                }
            }
        }

        .pagebuilder-column-line {
            flex-direction: column;

            @include mq($screen-l) {
                flex-wrap: nowrap;
                flex-direction: row-reverse;
            }
        }
    }
}
