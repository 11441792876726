// sass-lint:disable no-misspelled-properties no-important

.explore-section {
    padding: $spacer--extra-large 0;

    @include mq($screen-l) {
        padding: 84px 0;
    }

    @include mq($screen-xl) {
        padding: 110px 0;
    }

    &__container {
        max-width: $max-content-width;
        width: 100%;
        margin: 0 auto;
        padding: 0 $spacer;

        @include mq($screen-m) {
            padding: 0 $spacer--semi-large;
        }

        @include mq($screen-xl) {
            padding: 0;
        }

        .pagebuilder-column-line {
            gap: $spacer--semi-large;
            flex-flow: column;
            overflow: hidden;

            @include mq($screen-m) {
                gap: $spacer--extra-large;
            }

            @include mq($screen-xl) {
                flex-flow: row;
                gap: 130px;
            }
        }
    }

    &__image {
        width: 100% !important;

        @include mq($screen-xl) {
            width: 50% !important;
        }

        [data-content-type="image"] {
            margin: 0;
            height: auto;

            @include mq($screen-xl) {
                height: 0;
            }
        }

        img {
            width: 100%;
            aspect-ratio: 16 / 9;
            object-fit: cover;

            @include mq($screen-xl) {
                aspect-ratio: 1;
            }
        }
    }

    &__content {
        width: 100% !important;
        flex: 1 50%;
    }

    &__heading {
        font-size: $font-size-extra-large;
        font-weight: $font-weight-medium;

        @include mq($screen-m) {
            font-size: $font-size-extra-large-2;
        }
    }

    &__text {
        margin-bottom: $spacer--semi-large;

        @include mq($screen-m) {
            margin-bottom: $spacer--extra-large;
        }

        p:last-of-type {
            margin-bottom: 0;
        }
    }

    &__buttons {
        [data-content-type="button-item"],
        [data-content-type="button-item"] div {
            width: 100%;
        }

        @include mq($screen-m) {
            [data-content-type="button-item"],
            [data-content-type="button-item"] div {
                width: auto;
            }
        }
    }
}
