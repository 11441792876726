.career-offer {
    @include mq($screen-m) {
        padding: 0 $spacer--large 0;
    }

    @include mq($screen-l) {
        padding: 0;
        margin-bottom: $spacer--extra-large;
    }

    &__items  {
        @include mq($screen-m) {
            margin-bottom: $spacer--medium;
        }

        @include mq($screen-xl) {
            margin-top: $spacer--small;
        }
    }

    &__item {
        padding: $spacer--extra-large 0;
        max-width: 1120px;
        font-size: $font-size-large;

        &:last-child {
            .career-offer__description p:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__heading {
        margin: 0 0 $spacer--medium 0;

        font-size: $font-size-large-2;
    }

    &__description {
        font-size: $font-size-large;

        p {
            font-size: $font-size-large;
        }

        ul {
            margin: 0;
            padding-left: $spacer--semi-medium;

            li {
                margin: 0 0 $spacer--small 0;

                &:last-child {
                    margin: 0;
                }
            }
        }
    }

    &__divider {
        height: $border-width-base;
        border-bottom: $border-base;

        @include full-width();

        @include mq($screen-l) {
            width: auto;
            margin: 0;
        }
    }

    &__button {
        width: 100%;
        min-width: 196px;
        height: 56px;

        @include mq($screen-m) {
            width: auto;
        }
    }
}
