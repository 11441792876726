// sass-lint:disable no-misspelled-properties, no-important
.cms-hero {
    justify-content: center !important;
    padding: 0 $spacer--medium;
    margin: 0 0 $spacer--extra-large !important;
    border-bottom: $border-base;
    background: url('../images/wheel_half.png') no-repeat;
    background-size: auto clamp(100px, 310px, 90%) !important;
    background-position: bottom 0 left 40px !important;
    min-height: 382px;

    @include full-width();

    @include mq($screen-m) {
        padding: 0 $spacer--semi-large;
        background-position: bottom 0 right -400px !important;
        min-height: 320px;
    }

    @include mq($screen-l) {
        padding: 0 $spacer--semi-large;
        margin: 0 0 84px !important;
        background-position: bottom 0 right -520px !important;
    }

    @include mq($screen-xl) {
        padding: 0;
        background-position: bottom 0 right 10% !important;
        min-height: 330px;
    }

    &__content {
        max-width: 860px;
        margin: 0 auto;
    }

    &__heading {
        font-size: $font-size-extra-large-2;
        margin: 0 0 $spacer--semi-medium 0;

        @include mq($screen-m) {
            font-size: 50px;
            margin: 0 0 $spacer--large 0;
            letter-spacing: 1.88px;
        }
    }

    &__button {
        width: 100%;

        .pagebuilder-button-primary {
            width: 100%;

            @include mq($screen-m) {
                width: auto;
            }
        }
    }

    .pagebuilder-column-group {
        margin: 0 auto;
        width: 100%;
        max-width: $max-content-width;
    }
}
