div[data-content-type='tabs'] {
    padding-bottom: $spacer--extra-large !important; // sass-lint:disable-line no-important

    @include mq($screen-l) {
        padding: 0 0 110px !important; // sass-lint:disable-line no-important
        margin: 0 $spacer--semi-large;
    }

    @include mq($max-content-width) {
        width: $max-content-width;
        margin: auto !important; // sass-lint:disable-line no-important
    }

    .tabs-navigation {
        display: flex;
        flex-direction: column;
        width: 100%;
        border: none;
        padding: 0 $spacer;
        margin-bottom: $spacer--extra-large;

        @include mq($screen-m) {
            flex-direction: row;
            margin-bottom: 0;
            padding: 0;
        }

        li.tab-header {
            background-color: $gray-light;
            border: none;
            margin-bottom: $spacer--medium;
            min-height: 64px;

            @include mq($screen-m) {
                min-height: 80px;
                margin-bottom: 0;
            }

            a.tab-title {
                color: $color-primary;
                font-size: $font-size-large-2;
                line-height: 32px;
                letter-spacing: 0.69px;
                font-weight: $font-weight-bold;
                padding: $spacer--medium $spacer--medium;

                @include mq($screen-m) {
                    padding: $spacer--semi-medium;
                }
            }
        }
    }

    .tabs-content {
        padding: $spacer--extra-large $spacer;
        background-color: $white;
        border: none;

        @include mq($screen-m) {
            padding: 64px $spacer--semi-medium;
        }

        @include mq($screen-l) {
            padding-right: 64px;
            padding-left: 64px;
        }

        @include mq($screen-xl) {
            padding-right: $spacer--extra-large;
            padding-left: $spacer--extra-large;
            padding-bottom: 72px;
        }

        &__content-with-image {
            @include mq($screen-xl) {
                margin: 0 0 84px;
            }

            .pagebuilder-column-line {
                display: grid !important; // sass-lint:disable-line no-important
                column-gap: $spacer--semi-large;  // sass-lint:disable-line no-misspelled-properties
                row-gap: $spacer--extra-large;  // sass-lint:disable-line no-misspelled-properties

                @include mq($screen-xl) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @include mq($screen-xl) {
                    column-gap: 110px;   // sass-lint:disable-line no-misspelled-properties
                }
            }

            .pagebuilder-column {
                width: 100% !important; // sass-lint:disable-line no-important

                &:first-of-type {
                    grid-row: 2;

                    @include mq($screen-xl) {
                        grid-row: 1;
                    }
                }
            }

            figure[data-content-type='image'] {
                margin: 0;

                img {
                    width: 100%;
                }
            }
        }

        div[data-content-type='button-item'] {
            width: 100%;

            [data-element='link'] {
                width: 100%;
            }

            @include mq($screen-m) {
                width: auto;
            }
        }
    }

    &.pb-tabs--secondary {
        @include mq($screen-xl) {
            display: flex;
            max-width: $max-content-width;
            margin: auto;
        }


        .tabs-navigation {
            @include mq($screen-xl) {
                flex-direction: column;
                margin-right: $spacer--extra-large;
                max-width: 204px;
            }

            li.tab-header {
                @include mq($screen-xl) {
                    margin-bottom: $spacer--medium;
                }
            }
        }

        .tabs-content {
            @include mq($screen-xl) {
                padding: $spacer--extra-large;
                flex-grow: 1;
            }
        }
    }
}

.tabs-heading {
    .pagebuilder-column-line {
        max-width: $max-content-width;
        margin: $spacer--extra-large $spacer;
        width: auto !important; // sass-lint:disable-line no-important

        @include mq($screen-m) {
            margin: 110px $spacer--semi-medium 64px;
        }

        @include mq($screen-l) {
            margin: 110px $spacer--semi-large 64px;
        }

        @include mq($screen-xl) {
            margin: 110px auto 64px;
        }

        [data-content-type="heading"] {
            margin: 0;
        }
    }

    .pagebuilder-column {
        justify-content: space-between !important; // sass-lint:disable-line no-important
        gap: $spacer--semi-medium;  // sass-lint:disable-line no-misspelled-properties

        @include mq($screen-m) {
            flex-flow: row !important; // sass-lint:disable-line no-important
            align-items: center;
        }
    }
}

.tabs-notes {
    padding: 0 0 $spacer--extra-large;

    @include mq($screen-l) {
        padding: 0 0 64px;
    }

    .pagebuilder-column-line {
        display: grid !important; // sass-lint:disable-line no-important
        row-gap: $spacer--extra-large; // sass-lint:disable-line no-misspelled-properties
        column-gap: $spacer--semi-large;   // sass-lint:disable-line no-misspelled-properties

        @include mq($screen-xl) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__item {
        width: 100% !important;  // sass-lint:disable-line no-important

        p {
            margin: 0;
        }
    }

    &__item-heading {
        color: $blue;
        font-size: $font-size-large-2;
    }

    &--grid-with-image {
        display: flex;
        flex-wrap: wrap;
        gap: $spacer--extra-large; // sass-lint:disable-line no-misspelled-properties
        padding: 0;

        @include mq($screen-xl) {
            gap: 64px;  // sass-lint:disable-line no-misspelled-properties
        }

        .pagebuilder-column-line {
            &:nth-child(1) {
                flex: 1;
                grid-template-columns: 1fr;

                @include mq($screen-xl) {
                    order: 2;
                }

                figure {
                    margin: 0;
                }
            }

            &:nth-child(2) {
                flex-basis: 100%;
                grid-template-columns: 1fr;

                @include mq($screen-xl) {
                    order: 1;
                    flex: 0 60%;
                    grid-template-columns: repeat(2, 1fr);
                }
            }

            &:nth-child(3) {
                order: 3;
                grid-template-columns: 1fr;

                [data-content-type="buttons"] {
                    display: flex;
                    gap: $spacer--medium;   // sass-lint:disable-line no-misspelled-properties
                    flex-flow: column;

                    @include mq($screen-xl) {
                        padding-top: $spacer--medium;
                    }

                    .pagebuilder-button-primary,
                    .pagebuilder-button-secondary {
                        width: 100%;
                    }

                    @include mq($screen-m) {
                        flex-flow: row;

                        .pagebuilder-button-primary,
                        .pagebuilder-button-secondary {
                            width: initial;
                        }
                    }
                }
            }
        }
    }
}

.tabs-bottom-button {
    div[data-content-type='tabs'] {
        padding-bottom: $spacer--semi-large !important; // sass-lint:disable-line no-important

        @include mq($screen-m) {
            padding-bottom: 64px !important; // sass-lint:disable-line no-important
        }
    }

    > [data-content-type="buttons"] {
        padding-bottom: $spacer--semi-large;

        @include mq($screen-m) {
            padding-bottom: 84px;
        }

        [data-content-type="button-item"] div {
            height: 56px;
        }
    }
}
