$catalog-list__banner-margin\@large: 0 0 62px 0;
$catalog-list__banner-border-width\@large: 0 0 1px 0;
$catalog-list__banner-padding\@large: 0 0 $spacer--large 0;

.catalog-list {
    &__banner {
        position: relative;

        &:before {
            content: '';
            background: url('../images/wheel_half_3x.png') 0 / cover no-repeat;
            background-position: 25vw top;
            width: 100%;
            height: 100%;
            position: absolute;
            right: -#{$spacer};
            z-index: -1;
        }

        @include mq($screen-l) {
            margin: $catalog-list__banner-margin\@large;
            border-width: $catalog-list__banner-border-width\@large;
            padding: $catalog-list__banner-padding\@large;

            &:before {
                background-position: 0 top;
                transform: scale(0.75);
                transform-origin: bottom right;
                right: 0;
            }
        }
    }
}
