.cart-list-item {
    &__left {
        @include mq($screen-m) {
            flex-basis: 60%;
        }
    }

    &__right {
        @include mq($screen-m) {
            flex-basis: 40%;
        }
    }

    &__wrapper {
        @include mq($screen-m) {
            flex-basis: calc(100% / 3);
        }
    }

    &__qty {
        @include mq($screen-m) {
            flex-basis: calc(100% / 3);
        }
    }

    &__label {
        &.input__label {
            font-size: $font-size-base;
        }
    }

    &__price {
        font-size: $font-size-medium;
    }

    &__input {
        padding: 0;

        @include mq($screen-xl) {
            width: $cart-list-item__input-width;
        }
    }

    &__action {
        &[data-post*='delete'] svg {
            fill: $gray-dark;
        }
    }

    &__qty {
        @include mq($screen-m) {
            align-items: flex-start;
        }
    }

    &__content {
        padding: 0 $spacer--medium;
    }
}
