.career-list {
    @include mq($screen-m) {
        padding: 0 $spacer--large;
    }

    @include mq($screen-l) {
        padding: 0;
    }

    &__content {
        .career-list__divider:last-child {
            display: none;
        }
    }

    &__item {
        padding: $spacer--extra-large 0;

        @include mq($screen-m) {
            display: grid;
            grid-template-columns: 1fr auto;
            grid-template-rows: auto;
            column-gap: $spacer--large;
            margin: 0;
            padding: 84px 0;
        }

        @include mq($screen-xl) {
            padding: $spacer--extra-large 0;
        }

        &:first-of-type {
            @include mq($screen-xl) {
                padding: 84px 0 $spacer--extra-large;
            }
        }


        &:last-of-type {
            margin: 0;
            padding: $spacer--extra-large 0 $spacer--large;

            @include mq($screen-m) {
                padding: 84px 0 $spacer--extra-large;
            }

            @include mq($screen-xl) {
                padding: $spacer--extra-large 0;
            }
        }
    }

    &__description {
        font-size: $font-size-medium;
        margin: 0 0 $spacer--semi-medium 0;
        color: $color-secondary;

        @include mq($screen-m) {
            grid-row: 2;
            margin: 0;
            font-size: $font-size-large-2;
        }
    }

    &__link {
        @include mq($screen-m) {
            grid-column: 2;
            grid-row: 1 / 3;
            display: flex;
            align-items: center;
        }

        .button {
            width: 100%;
            min-width: 196px;
            height: 56px;

            @include mq($screen-m) {
                width: auto;
            }
        }
    }

    &__divider {
        height: 1px;
        border-bottom: 1px solid $gray;

        @include full-width();

        @include mq($screen-xl) {
            width: auto;
            margin: 0;
        }
    }

    &__empty {
        margin-top: $spacer--extra-large;
    }
}
