// sass-lint:disable no-important

.cart-grid {
    &__button {
        @include mq($screen-xl) {
            min-width: 320px;
        }
    }

    &__header-items {
        @include mq($screen-m) {
            flex-basis: 60%;
        }
    }

    &__header-price,
    &__header-subtotal {
        @include mq($screen-m) {
            flex-basis: 14%;
        }
    }

    &__header-qty {
        @include mq($screen-m) {
            flex-basis: 12%;
        }
    }
}
