.not-found {
    &__heading {
        font-size: $font-size-extra-large;
        text-transform: uppercase;
        text-align: center;

        @include mq($screen-m) {
            font-size: $font-size-super-extra-large;
        }
    }

    &__actions {
        list-style: none;
        padding: 0;

        @include mq($max-screen: $screen-m - 1) {
            li {
                display: inline-flex;
                justify-content: center;
            }
        }
    }

    &__text {
        margin-bottom: 0;
        padding: 0 $spacer;

        @include mq($screen-l) {
            padding: 0;
        }
    }
}
