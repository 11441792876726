.image-with-form-grid {
    &__image {
        margin: 0 auto $spacer--semi-large;
        width: 100%;

        @include mq($screen-m) {
            margin-bottom: 84px;
        }

        @include mq($screen-l) {
            margin-bottom: 0;
        }

        img {
            width: 100%;
            min-height: 255px;
            object-fit: cover;

            @include mq($screen-l) {
                min-height: 400px;
                object-position: -60px;
            }

            @include mq($screen-xl) {
                object-position: initial;
            }
        }
    }

    &__content {
        text-align: center;
        padding: 0 $spacer--medium $spacer--extra-large;
        flex-basis: 100%;

        @include mq($screen-m) {
            text-align: left;
            flex-wrap: wrap !important;
            flex-direction: row !important;
            padding: 0 $spacer--semi-large 84px;
        }

        @include mq($screen-l) {
            flex-basis: 60%;
            padding: 0  64px 0 $spacer--semi-large;
            align-self: center !important;
            max-width: calc(#{$max-content-width} / 2);
        }
    }

    &__heading {
        width: 100%;
    }

    &__text {
        display: flex;
        align-items: center;
        justify-content: center;

        @include mq($screen-l) {
            margin: $spacer 0;
        }

        a {
            color: $blue;
            font-weight: $font-weight-medium;
        }
    }

    &__buttons {
        margin-bottom: $spacer--medium;

        @include mq($screen-m) {
            margin-bottom: 0;
            margin-right: $spacer--medium;
        }

        .pagebuilder-button-primary {
            width: 250px;
        }
    }

    .pagebuilder-column-line {
        flex-direction: column;

        @include mq($screen-m) {
            flex-direction: row-reverse;
            flex-wrap: wrap;
        }

        @include mq($screen-l) {
            flex-wrap: nowrap;
        }
    }

    .pagebuilder-column {
        width: auto !important;

        @include mq($screen-l) {
            width: 50% !important;
        }
    }
}
