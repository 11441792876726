$headings-text-transform: none;
$headings-text-transform--page: none;
$headings-font-weight: $font-weight-bold;

$heading-font-size--first-level\@screen-m: 50px;

$heading-font-size--second-level: $font-size-extra-large;
$heading-font-size--second-level\@screen-m: 40px;

$heading-font-size--third-level: $font-size-large;
$heading-font-size--third-level\@screen-m: $font-size-extra-large-2;

$headings-margin--page: 62px 0 6px;
$headings-margin--page\@medium: 84px 0 0;
$heading-margin--page-full-width: $headings-margin--page;
$heading-margin--page-full-width\@medium: $headings-margin--page\@medium;
