// sass-lint:disable no-misspelled-properties, no-important
$brief-info-v5__column-gap\@medium: 60px;
$brief-info-v5__column-gap\@xlarge: 162px;
// Calc bellow: (100% / num of columns) - (gap / num of columns * num of gaps)
$brief-info-v5__column-width\@medium: calc(50% - #{$brief-info-v5__column-gap\@medium} / 2);
$brief-info-v5__column-width\@large: calc(33.33% - #{$brief-info-v5__column-gap\@medium} / 3 * 2);
$brief-info-v5__column-width\@xlarge: calc(33.33% - #{$brief-info-v5__column-gap\@xlarge} / 3 * 2);

.brief-info {
    padding: $spacer--extra-large 28px;

    @include mq($screen-m) {
        padding: 84px $spacer--semi-large;
    }

    @include mq($screen-l) {
        padding: 84px $spacer--semi-large;
    }

    @include mq($screen-xl) {
        padding: 110px 0;
    }

    &__image {
        width: 70px;
        margin: 0 0 $spacer--semi-medium 0;

        @include mq($screen-l) {
            margin: 0 $spacer--large 0 0;
            grid-row: 1 / 3;
        }

        @include mq($screen-xl) {
            width: 80px;
        }
    }

    &__heading {
        margin: 0 0 $spacer--medium 0 !important;
        font-size: 36px;
        font-weight: $font-weight-medium;
    }

    &__text {
        max-width: 230px;

        & > * {
            margin: 0;
            font-size: $font-size-large-2;
        }
    }

    .pagebuilder-column-group {
        display: flex;
    }

    .pagebuilder-column-line {
        width: auto !important;
        flex-direction: column;
        max-width: $max-content-width;
        padding: 0;
        margin: 0 auto;

        @include mq($screen-m) {
            display: grid !important;
            grid-template-columns: repeat(3, 1fr);
            column-gap: $spacer--semi-large;
            row-gap: $spacer--extra-large;
        }

        @include mq($screen-xl) {
            column-gap: 150px;
        }

        .pagebuilder-column {
            width: auto !important;
            flex-basis: auto;
            margin: 0 0 $spacer--extra-large 0;
            min-width: 264px;

            &:last-child {
                margin: 0;
            }

            @include mq($screen-m) {
                min-width: auto;
                margin: 0;
            }

            @include mq($screen-l) {
                display: grid !important;
                grid-template-columns: auto auto;
                grid-template-rows: auto auto;
                margin: 0;
            }
        }
    }

    > [data-content-type="heading"] {
        margin-bottom: $spacer--semi-large;

        @include mq($screen-m) {
            margin-bottom: 84px;
        }
    }

    &--v2 {
        padding: $spacer--extra-large $spacer;
        background-size: 0 !important;

        @include mq($screen-m) {
            background-size: 393px 372px !important;
        }

        @include mq($screen-l) {
            padding: 64px 74px;
            background-size: 477px 450px !important;
        }

        @include mq($screen-xl) {
            padding: 84px 0 110px;
        }

        .brief-info {
            &__image {
                display: flex;
                align-items: center;
                width: 75px;
                margin: 0 0 28px 0;
                min-height: 70px;
            }

            &__heading {
                font-size: $font-size-extra-large-2;
                font-weight: $font-weight-bold;
            }

            &__text {
                max-width: none;
                min-height: 114px;

                & > * {
                    font-size: $font-size-large;
                }
            }
        }

        .pagebuilder-column-line {
            @include mq($screen-m) {
                grid-template-columns: repeat(2, 1fr);
                gap: $spacer--semi-medium;
            }

            @include mq($screen-l) {
                gap: 26px;
            }

            @include mq($screen-xl) {
                grid-template-columns: repeat(3, 1fr);
            }

            .pagebuilder-column {
                width: 304px !important;
                margin: 0 0 26px 0;
                padding: $spacer--large $spacer--medium;


                @include mq($screen-m) {
                    margin: 0;
                }

                @include mq($screen-l) {
                    display: flex !important;
                    width: auto !important;
                    width: 426px !important;
                    padding: 64px $spacer--large;
                    margin: 0;
                }
            }
        }
    }

    &--v3 {
        padding: $spacer--extra-large $spacer $spacer--extra-large;

        @include mq($screen-m) {
            padding: 84px 70px;
        }

        @include mq($screen-l) {
            padding: 84px $spacer--semi-large;
        }

        @include mq($screen-xl) {
            padding: 110px 0;
        }

        .brief-info {
            &__image {
                flex-shrink: 0;
                width: 64px;
                margin: 0 $spacer--medium 0 0;
                align-self: center;
            }

            &__text {
                max-width: none;
                display: flex;
                align-items: center;

                & > * {
                    color: $color-primary;
                }
            }
        }

        .pagebuilder-column-line {
            @include mq($screen-m) {
                grid-template-columns: repeat(2, 1fr);
                column-gap: $spacer--semi-medium;
                row-gap: 30px;
            }

            @include mq($screen-l) {
                grid-template-columns: repeat(3, 1fr);
                column-gap: 38px;
            }

            @include mq($screen-xl) {
                column-gap: 64px;
            }

            .pagebuilder-column {
                display: flex !important;
                flex-direction: row !important;
                min-height: 110px;
                margin: 0 0 $spacer--semi-medium 0;

                @include mq($screen-m) {
                    margin: 0;
                }
            }
        }

        [data-content-type="heading"] {
            margin-bottom: $spacer--semi-large;

            @include mq($screen-m) {
                font-size: 40px;
                margin-bottom: 64px;
            }
        }
    }

    &--v4 {
        padding: $spacer--semi-large $spacer $spacer--extra-large;

        @include mq($screen-m) {
            padding: 84px 68px;
        }

        @include mq($screen-l) {
            padding: 84px 80px;
        }

        @include mq($screen-xl) {
            padding: 110px 0;
        }

        .pagebuilder-column-line {
            padding: $spacer--extra-large 0 0 0;

            @include mq($screen-m) {
                display: grid !important;
                grid-template-columns: repeat(2, 1fr);
                column-gap: $spacer--semi-medium;
                row-gap: $spacer--semi-large;
                padding: 84px 0 0 0;
            }

            @include mq($screen-l) {
                column-gap: 64px;
                row-gap: $spacer--semi-medium;
            }

            @include mq($screen-xl) {
                grid-template-columns: repeat(3, 1fr);
            }

            .brief-info {
                &__image {
                    flex-shrink: 0;
                    width: 64px;
                    margin: 0 $spacer--semi-medium 0 0;
                }

                &__text {
                    max-width: none;

                    & > * {
                        color: $color-primary;
                    }
                }
            }

            .pagebuilder-column {
                display: flex !important;
                flex-direction: row !important;
                align-items: center;
                min-height: 110px;
                margin: 0 0 $spacer--medium 0;

                @include mq($screen-m) {
                    margin: 0;
                }
            }
        }

        [data-content-type="heading"] {
            margin: 0 auto $spacer--large;
            max-width: 905px;
            text-align: left;

            @include mq($screen-m) {
                font-size: 40px;
                margin: 0 auto $spacer--semi-large;
                text-align: center;
            }
        }

        [data-content-type="text"] {
            max-width: 905px;
            margin: 0 auto;
            text-align: left;

            @include mq($screen-m) {
                text-align: center;
            }
        }
    }

    &--v5 {
        padding: $spacer--extra-large $spacer--semi-medium $spacer--extra-large;

        @include mq($screen-m) {
            padding: 84px 80px 84px;
        }

        @include mq($screen-l) {
            padding: 84px $spacer--semi-large 84px;
        }

        @include mq($screen-xl) {
            padding: 84px 0 110px;
        }

        .brief-info {
            &__image {
                width: 135px;
                margin: 0 0 $spacer--large 0;
            }

            &__text {
                max-width: none;

                & > * {
                    text-align: center;
                    color: $color-primary;
                }
            }
        }

        .pagebuilder-column-line {
            padding: $spacer--extra-large 0 0 0;

            @include mq($screen-m) {
                display: flex !important;
                flex-direction: row !important;
                justify-content: center;
                flex-wrap: wrap;
                gap: $brief-info-v5__column-gap\@medium;
                padding: 84px 0 0 0;
            }

            @include mq($screen-xl) {
                gap: $brief-info-v5__column-gap\@xlarge;
            }

            .pagebuilder-column {
                align-items: center;

                @include mq($screen-m) {
                    width: $brief-info-v5__column-width\@medium !important;
                }

                @include mq($screen-l) {
                    display: flex !important;
                    width: auto !important;
                    width: $brief-info-v5__column-width\@large !important;
                }

                @include mq($screen-xl) {
                    width: $brief-info-v5__column-width\@xlarge !important;
                }
            }
        }

        [data-content-type="heading"] {
            margin: 0 auto $spacer--large;
            max-width: 905px;
            text-align: left;

            @include mq($screen-m) {
                font-size: 40px;
                margin: 0 auto $spacer--semi-large;
                text-align: center;
            }
        }

        [data-content-type="text"] {
            max-width: 905px;
            margin: 0 auto;
            text-align: left;

            @include mq($screen-m) {
                text-align: center;
            }
        }
    }

    &--v6 {
        padding: $spacer--extra-large 0;

        @include mq($screen-m) {
            padding: 84px 0;
        }

        .pagebuilder-column-line {
            @include mq($screen-m) {
                grid-template-columns: repeat(2, 1fr);
                padding: 0 $spacer--large;
            }

            @include mq($screen-l) {
                column-gap: $spacer--semi-medium;
                padding: 0 $spacer--semi-large;
            }

            @include mq($screen-xl) {
                column-gap: 74px;
                padding: 0;
            }

            .pagebuilder-column {
                width: 100vw !important;
                padding: $spacer--semi-large $spacer--semi-medium;

                @include mq($screen-m) {
                    width: auto !important;
                }

                @include mq($screen-l) {
                    display: flex !important;
                    width: 459px !important;
                    padding: 64px;
                }


                [data-content-type="buttons"] {
                    display: flex;
                    justify-content: center;

                    div {
                        height: 56px;
                    }
                }
            }
        }

        .brief-info {
            &__image {
                display: flex;
                align-items: flex-end;
                width: 100px;
                height: 113px;
                margin: 0 auto $spacer--large auto;
            }

            &__heading {
                font-size: $font-size-extra-large-2 !important;
                text-align: center;
                margin: 0 0 $spacer--medium 0 !important;
            }

            &__text {
                flex-grow: 1;
                max-width: none;
                text-align: center;
                max-width: 275px;
                margin: 0 auto $spacer--extra-large;

                @include mq($screen-m) {
                    max-width: 331px;
                }

                p:first-child {
                    margin: 0 0 $spacer--large 0;
                }

                ul {
                    padding: 0 $spacer--large;

                    @include mq($screen-m) {
                        padding: 0 36px;
                    }

                    li {
                        position: relative;
                        list-style: none;
                        padding: 0 0 0 36px;
                        text-align: left;
                        margin: 0 0 $spacer--medium 0;

                        &::before {
                            content: '';
                            position: absolute;
                            left: 0;
                            top: 25%;
                            width: 21px;
                            height: 16px;
                            background-size: contain;
                            background-repeat: no-repeat;
                            background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' standalone='no'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 20010904//EN' 'http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd'%3E%3Csvg version='1.0' xmlns='http://www.w3.org/2000/svg' width='63.000000pt' height='47.000000pt' viewBox='0 0 63.000000 47.000000' preserveAspectRatio='xMidYMid meet'%3E%3Cg transform='translate(0.000000,47.000000) scale(0.100000,-0.100000)'%0Afill='%230173ae' stroke='none'%3E%3Cpath d='M395 300 c-87 -88 -160 -160 -163 -160 -2 0 -42 29 -87 65 l-84 65%0A-27 -35 c-33 -43 -39 -35 111 -149 l100 -75 38 37 c21 20 107 108 189 195%0Al151 158 -28 29 c-16 17 -31 30 -35 30 -3 0 -77 -72 -165 -160z'/%3E%3C/g%3E%3C/svg%3E%0A");
                        }
                    }
                }
            }
        }

        [data-content-type="heading"] {
            margin: 0 auto $spacer--semi-large;
            max-width: $max-content-width;

            @include mq($screen-m) {
                font-size: 40px;
                margin: 0 auto 64px;
                text-align: center;
            }
        }
    }

    &--v7 {
        padding: $spacer--extra-large $spacer 110px;

        @include mq($screen-m) {
            padding: 84px $spacer--semi-medium;
        }

        @include mq($screen-l) {
            padding: 84px $spacer--semi-large;
        }

        @include mq($screen-xl) {
            padding: 110px 0;
        }

        [data-content-type="heading"] {
            margin: 0 auto $spacer--large;
            max-width: 905px;
            text-align: left;

            @include mq($screen-m) {
                font-size: 40px;
                margin: 0 auto $spacer--semi-large;
                text-align: center;
            }
        }

        [data-content-type="text"] {
            max-width: 905px;
            margin: 0 auto;
            text-align: left;

            @include mq($screen-m) {
                text-align: center;
            }

            p:last-child {
                margin: 0;
            }
        }

        .pagebuilder-column-line {
            gap: $spacer--medium;
            margin: $spacer--extra-large 0 0;

            @include mq($screen-m) {
                grid-template-columns: 1fr 1fr;
                gap: $spacer--small;
                margin: 84px 0 0;
                padding: 0 $spacer--extra-large;
            }

            @include mq($screen-l) {
                grid-template-columns: repeat(3, 1fr);
                padding: 0;
            }

            @include mq($screen-xl) {
                margin: 84px auto 0;
            }

            .pagebuilder-column {
                margin: 0;
            }
        }

        figure {
            width: 100%;
            margin: 0;
        }
    }

    &--align-center {
        .pagebuilder-column {
            justify-content: center;
            align-items: center;
        }
    }

    &--bg-desktop-hidden {
        @include mq($screen-xl) {
            background-image: none !important;
        }
    }
}
