// sass-lint:disable no-misspelled-properties no-important

.card {
    width: 100% !important;

    &__picture {
        width: 100%;
        margin: 0;

        img {
            min-width: 100%;
        }
    }

    &__heading {
        margin-top: $spacer--large !important;
        font-size: $font-size-large-2;
        padding: 0 $spacer;

        @include mq($screen-m) {
            font-size: $font-size-extra-large-2;
        }
    }

    &__text {
        flex: 1;
        padding: 0 $spacer;

        p {
            font-size: $font-size-medium;
            margin: 0;

            @include mq($screen-m) {
                font-size: $font-size-large;
            }
        }
    }

    &__buttons {
        gap: $spacer--medium;
        padding: 0 $spacer;

        @include mq($screen-m) {
            gap: $spacer--semi-medium;
        }

        &--fluid {
            width: 100%;

            [data-content-type="button-item"],
            .pagebuilder-button-primary {
                width: 100%;
            }
        }

        .pagebuilder-button-link {
            font-size: $font-size-large;
            min-height: 40px;
            line-height: 40px;
            color: $blue;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }
}

.cards {
    display: flex;
    flex-direction: column;
    padding: $spacer--extra-large 0;
    background-size: 0 !important;
    background-position: bottom right !important;

    @include mq($screen-m) {
        padding: 84px 0;
        background-size: auto !important;
    }

    @include mq($screen-l) {
        padding: 110px 0;
    }

    @include mq($screen-xl) {
        background-size: 0 !important;
    }

    [data-content-type="heading"],
    &__heading {
        margin-bottom: $spacer--semi-large !important;

        @include mq($screen-m) {
            margin-bottom: 84px !important;
        }
    }

    .pagebuilder-column-line {
        display: grid !important;
        gap: $spacer--extra-large;
        margin: 0 auto;
        padding: 0 $spacer;
        max-width: 1328px;
        width: auto !important;

        @include mq($screen-m) {
            gap: 72px;
            grid-template-columns: repeat(2, 1fr);
            padding: 0 $spacer--extra-large;
        }

        @include mq($screen-xl) {
            grid-template-columns: repeat(3, 1fr);
            padding: 0;
        }
    }

    &--v1 {
        .card {
            &__heading {
                font-weight: $font-weight-medium;
                margin-bottom: $spacer--medium !important;
                text-align: center;
            }

            &__text {
                font-weight: $font-weight-medium;
                margin-bottom: $spacer--large;
                text-align: center;
            }

            &__buttons {
                display: flex;
                justify-content: center;
            }

            .pagebuilder-button-link {
                font-weight: $font-weight-medium;
            }
        }
    }

    &--v2 {
        .pagebuilder-column-line {
            gap: $spacer--semi-medium;

            @include mq($screen-m) {
                max-width: 878px;
            }

            @include mq($screen-l) {
                gap: $spacer--medium;
            }

            @include mq($screen-xl) {
                max-width: $max-content-width;
            }
        }

        .card {
            align-items: center;
            justify-content: center !important;
            height: 100%;
            width: 100%;
            min-height: 233px;
            max-height: 330px;
            max-width: 431px;
            margin: auto;
            padding: 0 $spacer--semi-medium;

            @include mq($screen-m) {
                padding: 0 $spacer--extra-large;
            }

            @include mq($screen-l) {
                height: 330px;
            }

            [data-content-type="button-item"],
            .pagebuilder-button-primary {
                width: 100%;
                height: 100%;
            }

            &__buttons {
                display: inline-flex;
                align-items: center;
                width: 100%;
                height: 33.33%;
                padding: 0;

                span {
                    font-size: $font-size-large;

                    @include mq($screen-m) {
                        font-size: $font-size-large-2;
                    }
                }
            }

            .pagebuilder-button-primary {
                padding: $spacer--medium;

                span {
                    line-height: 1.6em;
                }
            }
        }
    }

    &--v3 {
        .pagebuilder-column-line {
            @include mq($screen-m) {
                display: flex !important;
                flex-direction: column;
                gap: 64px;
                padding: 0 $spacer--semi-medium;
            }

            @include mq($screen-l) {
                padding: 0 $spacer--semi-large;
            }

            @include mq($screen-xl) {
                display: grid !important;
                grid-template-columns: 1fr 1fr;
                column-gap: 70px;
                row-gap: 64px;
                padding: 0;
            }
        }

        .card {
            width: 100vw !important;
            margin: 0 0 0 calc(50% - 50vw);
            padding-bottom: $spacer--semi-large;

            @include mq($screen-m) {
                width: auto !important;
                margin: auto !important;
                padding-bottom: $spacer--large;
            }

            &__heading,
            &__text,
            &__buttons {
                padding: 0 $spacer;

                @include mq($screen-m) {
                    padding: 0 $spacer--large;
                }
            }

            &__heading {
                margin: $spacer--large 0 $spacer--semi-medium !important;
                font-size: $font-size-large;

                @include mq($screen-m) {
                    font-size: $font-size-extra-large-2;
                }
            }

            &__picture {
                img {
                    max-height: 270px;
                    object-fit: cover;
                    object-position: top;
                }
            }
        }
    }

    &--v4 {
        @extend .cards--v1;

        .pagebuilder-column-line {
            gap: $spacer--extra-large;

            @include mq($screen-m) {
                column-gap: 30px;
                row-gap: 72px;
                grid-template-columns: repeat(2, 1fr);
            }

            @include mq($screen-xl) {
                grid-template-columns: repeat(4, 1fr);
            }
        }
    }

    &--bg-mobile-hidden {
        background-size: 0 !important;

        @include mq($screen-m) {
            background-size: auto 40% !important;
            background-position: bottom 0 right -400px !important;
        }

        @include mq($screen-xl) {
            background-size: auto clamp(100px, 75%, 452px) !important;
            background-position: bottom 0 left -400px !important;
        }
    }
}
