// sass-lint:disable no-misspelled-properties no-important

$features-list__border-width : 5px;
$features-list__border-offset: calc(#{$features-list__border-width} + 24px);

@mixin features-list-border {
    content: '';
    border-left: $features-list__border-width solid $blue;
    position: absolute;
    width: $features-list__border-width;
    height: 100%;
    top: 0;
    left: 0;
}

.features-list {
    padding: 48px 0 0 0;

    @include mq($screen-m) {
        padding: 84px 0 0 0;
    }

    @include mq($screen-m) {
        padding: 110px 0 0 0;
    }

    &__column {
        margin-bottom: $spacer--semi-large;
        min-width: 100%;

        @include mq($screen-m) {
            width: 100% !important; // sass-lint:disable-line no-important
            padding: 0 $spacer--semi-medium;
        }

        @include mq($screen-l) {
            padding: 0 $spacer--semi-large;
        }

        @include mq($screen-xl) {
            width: $max-content-width / 2 !important; // sass-lint:disable-line no-important
            min-width: auto;
            padding: 0;
        }
    }

    &__heading {
        position: relative;
        padding: 0 $spacer $spacer--semi-medium $features-list__border-offset;
        margin-bottom: 0 !important; // sass-lint:disable-line no-important
        font-size: $font-size-large;
        line-height: 32px;
        letter-spacing: 0.69px;

        @include mq($screen-m) {
            padding: 0 $spacer--semi-medium $spacer--semi-medium $features-list__border-offset;
            font-size: 28px;
            line-height: 38px;
            letter-spacing: 0.88px;
        }

        @include mq($screen-xl) {
            padding: 0 64px $spacer--semi-medium $features-list__border-offset;
        }

        &:before {
            @include features-list-border;
        }
    }

    &__text {
        position: relative;
        padding: 0 $spacer 0 $features-list__border-offset;

        @include mq($screen-m) {
            padding: 0 $spacer--semi-medium 0 $features-list__border-offset;
        }

        @include mq($screen-xl) {
            padding: 0 64px 0 $features-list__border-offset;
        }

        &:before {
            @include features-list-border;
        }

        & > * {
            line-height: 26px;

            @include mq($screen-m) {
                line-height: 30px;
            }
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    &__buttons {
        display: flex;
        gap: $spacer--medium;
        padding: 0 $spacer $spacer--extra-large;

        @include mq($screen-l) {
            padding: 44px $spacer--semi-large 110px;
        }

        @include mq($screen-xl) {
            padding: 44px 0 $spacer--semi-large;
        }

        .pagebuilder-button-primary,
        .pagebuilder-button-secondary {
            width: 100%;
        }

        [data-content-type="button-item"] {
            min-width: 100%;

            @include mq($screen-l) {
                min-width: 196px;
            }
        }

    }

    .pagebuilder-column-group {
        margin-bottom: 0 0 $spacer--small 0;
        max-width: $max-content-width;

        @include mq($screen-m) {
            margin-bottom: 0 0 70px 0;
        }

        @include mq($screen-xl) {
            margin: 0 auto 70px;
        }
    }

    .pagebuilder-column-line {
        flex-direction: column;

        @include mq($screen-m) {
            flex-direction: column;
        }

        @include mq($screen-xl) {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
        }
    }

    .container {
        @include mq($screen-xl) {
            min-width: $max-content-width;
        }
    }
}
