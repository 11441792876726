.banner {
    &--category {
        .banner__button-collapse {
            background: transparent;
        }

        .banner__button-text {
            text-decoration: none;
            font-weight: $font-weight-base;
        }


        .banner__heading-wrapper {
            background: transparent;
            position: relative;
            justify-content: flex-end;
            margin-top: 130px;
            max-width: 100%;
            inset: 0;
            padding: 0;

            @include mq($screen-m) {
                align-items: flex-end;
                justify-content: space-between;
                flex-direction: row;
                margin-top: 90px;
            }
        }

        .heading {
            font-size: $font-size-extra-large;
            text-transform: uppercase;

            @include mq($screen-m) {
                font-size: $banner-category-heading-font-size\@medium;
                text-transform: none;
            }
        }

        .banner__button-text {
            color: $color-secondary;
        }
    }
}



