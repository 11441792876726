$slider__item-margin-hz--articles: $spacer--extra-small;
$slider__item-margin-hz--articles\@large: $spacer;

.slider {
    &__nav-button {
        border-color: $gray;
    }

    &__nav-icon {
        &.icon {
            fill: $gray-dark;
        }
    }

    &__dots {
        li {
            margin: $spacer--medium 0 0;
        }
    }

    &__prev {
        @include mq($screen-xl) {
            left: -8px;
        }

    }

    &__next {
        @include mq($screen-xl) {
            right: -8px;
        }
    }

    &__handler {
        overflow: hidden;

        @include mq($screen-m) {
            margin: 0 $spacer--semi-large;
        }

        @include mq($screen-xl) {
            margin: 0;
            overflow: initial;
        }
    }

    &--recently-viewed {
        .slick-list {
            @include mq($screen-m) {
                // Remove margin from the first and last slider__item displayed to align with arrow buttons
                margin: 0 -#{$spacer};
            }
        }
    }

    &--articles {
        width: calc(100vw - #{$slider__item-margin-hz--articles} - #{$spacer});
        right: 0;

        @include mq($screen-l) {
            width: calc(100vw - #{$slider__item-margin-hz--articles\@large} * 2 - #{$spacer--semi-large});
        }

        @include mq($screen-xl) {
            width: 100%;
        }

        .slider__item {
            margin: 0 $slider__item-margin-hz--articles;
            // Max-width to prevent jump on load
            max-width: 267px;

            @include mq($screen-s) {
                max-width: 406px;
            }

            @include mq($screen-m) {
                max-width: 340px;
            }

            @include mq($screen-l) {
                max-width: 432px;
                margin: 0 $slider__item-margin-hz--articles\@large;
            }

            @include mq($screen-xl) {
                max-width: 432px;
            }
        }

        // Remove margin from the first and last slider__item displayed
        .slick-list {
            margin: 0 -#{$slider__item-margin-hz--articles};

            @include mq($screen-l) {
                margin: 0 -#{$slider__item-margin-hz--articles\@large};
            }
        }

        .slider__navigation {
            @include mq($screen-m) {
                display: none;
            }

            @include mq($screen-xl) {
                display: block;
            }
        }
    }
}
