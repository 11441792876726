.dashboard-table {
    &__link {
        color: $blue;
    }

    &__title {
        text-transform: capitalize;
    }

    &__header {
        .link {
            margin-top: $spacer--medium;

            @include mq($screen-m) {
                margin: 0;
            }
        }
    }

    &__content {
        border-top: $border-base;

        @include mq($screen-m) {
            border-top: none;
        }

        @include mq($screen-l) {
            margin: 0 0 $spacer--large;
        }

        table {
            th {
                padding: $spacer--medium $spacer;
            }

            td {
                font-size: $font-size-medium;
                padding: $spacer--medium;

                &:before {
                    max-width: 33%;
                    width: 33%;
                    min-width: 33%;
                }

                @include mq($screen-m) {
                    padding: $spacer--medium $spacer;
                }
            }

            tr {
                padding: $spacer 0;

                @include mq($screen-m) {
                    border-top: none;
                }
            }
        }

        .price {
            color: $gray-darker;
        }
    }
}
