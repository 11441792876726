//sass-lint:disable no-important
.notes {
    padding: 56px $spacer--medium $spacer--extra-large;
    background-size: auto 30% !important;
    background-position: left 0 bottom 0 !important;

    @include mq($screen-m) {
        padding: 56px $spacer--semi-large 86px;
        background-size: auto 60% !important;
        background-position: left 240px bottom 0 !important;
    }

    @include mq($screen-l) {
        padding: 56px $spacer--semi-large 68px;
        background-position: left 440px bottom 0 !important;
    }

    @include mq($screen-xl) {
        padding: 56px 0 68px;
        background-size: auto 80% !important;
        background-position: right 15% bottom 0 !important;
    }

    &--breadcrumbs-within {
        padding: 115px $spacer--medium $spacer--extra-large;

        @include mq($screen-m) {
            padding: 96px $spacer--semi-large 86px;
        }

        @include mq($screen-l) {
            padding: 96px $spacer--semi-large 68px;
            background-position: left 440px bottom 0 !important;
        }

        @include mq($screen-xl) {
            padding: 96px 0 68px;
            background-size: auto 80% !important;
            background-position: right 15% bottom 0 !important;
        }
    }

    &__heading {
        margin: auto;
        width: 100%;
        max-width: $max-content-width;

        [data-content-type="heading"] {
            margin-bottom: $spacer--extra-large;
            width: 100%;
            font-size: $font-size-extra-large-2;
            text-align: left;

            @include mq($screen-m) {
                margin-bottom: 56px;
                font-size: 50px;
            }
        }
    }

    &__details {
        margin: auto;
        max-width: $max-content-width;
        width: 100%;

        .pagebuilder-column-line {
            flex-direction: column;

            @include mq($screen-m) {
                display: grid !important;
                grid-template-rows: repeat(2, auto);
                grid-template-columns: repeat(3, auto);
                row-gap: $spacer--semi-large; //sass-lint:disable-line no-misspelled-properties
                column-gap: 70px;
            }

            @include mq($screen-l) {
                grid-template-columns: repeat(4, auto);
                column-gap: $spacer--semi-large;
            }

            @include mq($screen-xl) {
                grid-template-columns: repeat(5, auto);
                grid-template-rows: 1fr;
            }

            .pagebuilder-column {
                justify-content: center !important; //sass-lint:disable-line no-misspelled-properties
                margin: 0 0 $spacer--semi-large 0;
                width: 100% !important;

                @include mq($screen-m) {
                    margin: 0;
                }

                &:last-child {
                    margin: 0;

                    @include mq($screen-m) {
                        grid-column: 2 / 4;
                        align-items: flex-end;
                    }

                    @include mq($screen-l) {
                        grid-column: 4 / 5;
                        grid-row: 2;
                    }

                    @include mq($screen-xl) {
                        grid-column: auto;
                        grid-row: auto;
                    }
                }

                &:nth-last-child(2) {
                    @include mq($screen-l) {
                        grid-row: 2;
                    }

                    @include mq($screen-xl) {
                        grid-row: auto;
                    }
                }
            }

            [data-content-type="heading"] {
                margin-bottom: $spacer;
                font-size: $font-size-large-2;
                color: $blue;
            }

            [data-content-type="text"] p {
                margin: 0;
                font-size: $font-size-large;
            }
        }
    }

    &__button {
        width: 100%;

        div {
            width: 100%;
            height: 56px;

            @include mq($screen-m) {
                width: auto;
                min-width: 196px;
            }
        }
    }
}
