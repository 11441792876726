// sass-lint:disable no-misspelled-properties

.newsletter {
    justify-content: space-between;

    &__heading {
        flex-basis: 30%;

        @include mq($screen-l) {
            margin: 0 42px 0 0;
        }
    }


    &__form {
        @include mq($screen-xl) {
            flex-grow: 0;
        }
    }

    &__title {
        white-space: nowrap;

        @include mq($screen-m) {
            font-size: $font-size-extra-large-2;
        }
    }

    &__subtitle {
        margin-bottom: $spacer--semi-medium;
    }

    &__form {
        flex: 1;

        @include mq($screen-m) {
            max-width: 450px;
        }

        .hs-form-field {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            align-items: baseline;
            margin-bottom: $spacer--medium;

            @include mq($screen-m) {
                flex-direction: row;
            }
        }

        .hs-form-field label,
        .hs-error-msgs li * {
            margin: 0;
            color: white;
            font-size: $font-size-base;
        }

        .hs-form-field label:not(.hs-error-msg) {
            flex: 0 30%;
            font-size: $font-size-medium;
            margin: 0 $spacer $spacer 0;

            @include mq($screen-m) {
                margin: 0;
            }
        }

        .hs-form-field input,
        .hs-form-field select {
            width: 100%;
            height: $spacer--extra-large;
            padding: 0 $spacer--medium;
            border: $form-elements-border;
        }

        .hs-form-field select {
            appearance: none;
        }

        .hs-form-field .input,
        .hs-form-field .input ul {
            margin: 0;
            padding: 0;
        }

        .hs-form-field .input  {
            position: relative;
            flex: 1;
            width: 100%;
        }

        .hs-form-field .input ul {
            list-style: none;
        }

        .hs-fieldtype-select .input:after {
            content: '';
            position: absolute;
            top: 20px;
            right: $spacer--medium;
            width: $spacer--small;
            height: $spacer--small;
            border-style: solid;
            border-color: $color-primary;
            border-width: 0 0 2px 2px;
            transform: rotate(-45deg) translateY(-50%);
            pointer-events: none;
        }

        .hs-error-msgs {
            flex: 0 100%;
            margin: 0;
            padding: $spacer--extra-small 0 $spacer;
            list-style: none;

            @include mq($screen-m) {
                margin: 0 0 0 30%;
            }
        }

        .hs-button {
            align-self: flex-start;
            width: 100%;
            min-width: 96px;
            min-height: $spacer--extra-large;
            padding: 0 $spacer--medium;
            font-family: inherit;
            font-weight: $font-weight-bold;
            text-transform: uppercase;
            background: transparent;
            color: white;
            border: 2px solid #fff;
        }

        .hs-form-required {
            color: $color-error;
        }

        .hs-form-booleancheckbox input {
            flex: 0 $spacer--semi-medium;
            height: $spacer--semi-medium;
            margin-right: $spacer;
            accent-color: $white;
        }

        .hs-form-booleancheckbox-display {
            display: inline-flex;
            width: 100%;
            margin: 0;
        }

        .hs-form-booleancheckbox-display a {
            color: inherit;
            text-decoration: underline;
        }

        .hs-form-booleancheckbox-display span {
            font-size: $font-size-small;
        }

        .hs-fieldtype-booleancheckbox .hs-error-msgs {
            padding: $spacer--extra-small 0 $spacer;

            @include mq($screen-m) {
                margin: 0 0 0 $spacer--semi-medium;
            }
        }

        .hs_error_rollup ul {
            margin: 0 0 $spacer--medium 0;
        }

        .submitted-message {
            color: $white;
            font-size: $font-size-medium;

            @include mq($screen-m) {
                text-align: center;
            }
        }
    }
}
