.article-item {
    &--single {
        padding: 0 $spacer--medium;

        @include mq($screen-l) {
            padding: 0;
        }

        @include mq($screen-xl) {
            padding: 0 $spacer;
        }

        .article-item {
            &__media {
                @include mq($screen-l) {
                    flex-basis: auto;
                    max-width: none;
                }

                @include mq($screen-xl) {
                    margin: 0;
                }
            }

            &__body {
                @include mq($screen-l) {
                    flex-basis: auto;
                    max-width: none;
                }
            }

            &__banner img {
                margin: auto;
            }

            &__content {
                margin-bottom: 0;
            }

            &__footer {
                margin-top: 54px;

                @include mq($screen-l) {
                    margin-top: 80px;
                }
            }
        }
    }

    &__title-link {
        font-weight: inherit;
    }

    &__title {
        padding: 0;
        line-height: 24px;
        margin-bottom: $spacer;

        @include mq($screen-m) {
            line-height: 48px;
            margin-bottom: 0;
        }
    }

    &__media {
        @include mq($screen-l) {
            flex-basis: 37.5%;
            max-width: 37.5%;
        }
    }

    &__body {
        @include mq($screen-l) {
            flex-basis: 62.5%;
            max-width: 62.5%;
        }
    }

    &__sub-title {
        color: $gray-darker;
    }

    &__footer-link {
        color: $color-primary;
    }
}
