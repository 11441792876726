.breadcrumbs {
    @include mq($screen-l) {
        padding: $spacer 0;
    }

    &--white {
        .breadcrumbs {
            &__item:after,
            &__item:last-child:not(:first-child),
            &__link {
                color: $white;
            }

            &__link:hover {
                color: $blue;
            }
        }
    }

    &--careers {
        .breadcrumbs {
            margin: 0 $spacer--medium;

            @include mq($screen-m) {
                margin: 0 $spacer--semi-large;
            }

            @include mq($screen-xl) {
                margin: 0 auto;
            }
        }
    }
}
