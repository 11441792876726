.table {
    border-width: 0 0 $table__border-width-base;

    th,
    td:before {
        color: $color-primary;
    }

    &--scope-row {
        border-width: $table__border-width--scope-row;
        font-size: $font-size-large;

        tbody {
            th {
                font-weight: $font-weight-medium;
            }

            tr {
                &:last-child {
                    border-bottom: 0;

                    th,
                    td {
                        @include mq($screen-m) {
                            border-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
