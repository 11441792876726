.product-view {
    &__title {
        &.heading--page {
            @include mq($screen-xl) {
                margin-bottom: $spacer--semi-medium;
            }
        }
    }

    &__wrapper {
        @include mq($screen-m) {
            display: flex;
        }

        @include mq($screen-l) {
            display: grid;
        }
    }

    &__product-details {
        padding: $spacer--extra-large 0 54px;

        @include mq($screen-m) {
            padding: 110px 0 84px;
        }

        @include mq($screen-l) {
            padding-bottom: 110px;
        }

        h2 {
            @include mq($screen-m) {
                text-align: center;
                margin-bottom: 64px !important; // sass-lint:disable-line no-important
            }
        }

        p {
            margin: 0;
        }
    }

    &__related {
        margin: $spacer--extra-large 0;

        @include mq($screen-l) {
            margin: 84px 0;
        }

        .product-grid-item {
            max-width: 350px;
            margin: 0 auto;
        }
    }

    &__short-description {
        ul {
            line-height: 2;
            padding-inline-start: $spacer--semi-medium;
            margin: 0;
        }
    }

    &__qty {
        @include mq($screen-m) {
            margin-bottom: $spacer--semi-medium;
        }
    }

    &__buttons-wrapper {
        &--ee {
            .towishlist {
                @include mq($screen-m) {
                    display: flex;
                }
            }
        }
    }

    &__addtowishlist,
    &__add-to-cart {
        display: inline-flex;

        .product-view__buttons-wrapper--ee & {
            display: inline-flex;
        }

        .button__text {
            display: inline-flex;
        }
    }

    &__add-to-cart {
        margin-bottom: $spacer;

        @include mq($screen-m) {
            margin-bottom: 0;
        }
    }

    &__product-details {
        padding: $spacer--extra-large 0 54px;
        margin: 0 8%;

        @include mq($screen-m) {
            padding: 110px 0 84px;
        }

        @include mq($screen-l) {
            padding-bottom: 110px;
        }

        @include mq($screen-xxl) {
            margin: 0;
        }

        h2 {
            @include mq($screen-m) {
                text-align: center;
                margin-bottom: 64px !important; // sass-lint:disable-line no-important
            }
        }

        p {
            margin: 0;
        }
    }

    .section__heading {
        margin-bottom: $spacer--semi-large;

        @include mq($screen-m) {
            margin-bottom: 64px;
        }

        h2 {
            font-size: $font-size-extra-large;

            @include mq($screen-m) {
                font-size: 40px;
            }
        }

        p {
            margin: 0;
        }
    }

    &__rating {
        margin-bottom: $spacer--medium;

        @include mq($screen-l) {
            margin-bottom: 0;
        }

        @include mq($screen-xl) {
            padding-bottom: $spacer--semi-medium;
        }
    }

    &__price {
        padding: 0;
        @include mq($screen-m) {
            padding: 0;
        }

        .price {
            padding: $spacer--medium 0;
            @include mq($screen-m) {
                padding-bottom: $spacer--semi-large;
            }
        }
    }

    &__stock {
        line-height: 30px;
    }

    &__grouped-table {
        .product-view__grouped-product-qty-td {
            width: 22%;
        }
    }
}

.page-product-grouped {
    .product-view {
        &__rating {
            display: none;
        }

        &__price {
            display: none;
        }
    }
}

.page-product-configurable {
    .product-view {
        &__rating {
            display: none;
        }
    }
}
