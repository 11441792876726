// sass-lint:disable no-misspelled-properties, no-important
.cms-content {
    width: 100% !important;
    margin: 0 0 $spacer--extra-large;

    @include mq($screen-xl) {
        min-width: 860px;
    }

    &__heading {
        font-size: $font-size-large-2;
    }

    &__text {
        & p:last-of-type {
            margin-bottom: 0;
        }
    }

    &__list {
        ul {
            padding: 0 0 0 32px;
            margin: 0;
        }

        li {
            &::marker {
                font-size: $font-size-extra-large;
            }
        }
    }

    &__wrapper--with-image {
        .pagebuilder-column-line {
            flex-direction: column-reverse;

            @include mq($screen-xl) {
                flex-direction: row;
            }
        }
    }

    &__image-wrapper {
        width: 100% !important;
    }

    &__image {
        margin: 0 0 $spacer--extra-large;

        @include mq($screen-l) {
            margin: 0 0 84px;
        }

        img {
            @include mq($screen-xl) {
                margin-left: auto;
            }
        }
    }

    [data-content-type="divider"] {
        margin: $spacer--extra-large 0;
    }
}
