.totals-tax {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    .mark,
    .amount {
        display: block;
    }
}
