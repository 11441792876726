.order-summary {
    &__title {
        line-height: 48px;
    }

    &__total {
        .order-summary__amount {
            color: $color-primary;
        }
    }
}

.checkout-container {
    .order-summary {
        padding: $spacer--extra-large $spacer--medium $spacer--medium;

        @include mq($screen-m) {
            padding: $spacer--semi-medium $spacer--extra-large;
            background-color: $gray-lighter;
            margin-top: $spacer--extra-large;
        }

        @include mq($screen-l) {
            margin-top: 0;
        }

        &__list {
            padding: $spacer--medium 0 72px;
            border-width: 0;
        }

        &__title {
            line-height: 48px;
        }

        &__tab-title {
            cursor: default;

            @include mq($screen-l) {
                cursor: pointer;
            }
        }

        &__tab-text {
            cursor: default;
            color: $navy;
            font-size: $font-size-medium;

            @include mq($screen-l) {
                cursor: pointer;
            }
        }

        &__tab-icon {
            display: none;

            @include mq($screen-l) {
                display: flex;
                fill: $gray;
                width: 24px;
                height: 24px;
            }
        }

        &__product-name {
            line-height: 24px;
            margin-bottom: $spacer;
            color: $navy;
        }

        &__product-price {
            font-weight: $font-weight-bold;
        }

        &__product-info {
            @include mq($screen-l) {
                margin-bottom: $spacer;
            }

            .price {
                font-weight: $font-weight-bold;
            }
        }

        &__value {
            .price {
                @include mq($screen-l) {
                    color: $gray-darker;
                }
            }
        }

        &__amount {
            @include mq($screen-l) {
                color: $navy;
            }
        }

        &__product-image {
            @include mq($screen-l) {
                margin-right: $spacer--medium;
            }
        }
    }
}

.shopping-cart {
    .order-summary {
        background-color: $gray-lighter;
        padding: 24px;

        @include mq($screen-xxl) {
            padding-left: $spacer--extra-large;
            padding-right: $spacer--extra-large;
        }

        &__subtitle {
            line-height: 48px;
            margin: $spacer 0 0;
        }

        &__list {
            border-width: 1px 0 0 0;
        }
    }
}
