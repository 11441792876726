.minicart-content {
    @include mq($screen-m) {
        padding: 0 64px;
    }

    @include mq($screen-l) {
        padding: 0;
    }

    &__summary {
        line-height: 3;

        @include mq($screen-m) {
            padding-bottom: 0;
        }
    }

    &__edit {
        margin-bottom: $spacer--medium;

        @include mq($screen-m) {
            margin-bottom: 0;
        }
    }

    &__bottom {
        position: static;
    }

    &__counter {
        color: $gray-darker;
    }

    &__actions {
        @include mq($screen-m) {
            flex-flow: row wrap;
        }
    }

    &__top {
        @include mq($screen-m) {
            padding-bottom: $spacer--semi-medium;
        }
    }

    &__bottom {
        @include mq($screen-m) {
            padding-top: 0;
        }
    }

    &__action-button {
        @include mq($screen-m) {
            width: calc(50% - #{$spacer--medium});
        }
    }

    &__extra-actions {
        @include mq($screen-m) {
            flex: 0 0 100%;
        }
    }

    &__heading {
        @include mq($screen-m) {
            letter-spacing: 0.75px;
        }
    }

    &__summary-label {
        .price {
            color: $gray-darker;
        }
    }
}
