$input-max-width: 315px;
$form-width: 625px;

.banner-with-form {
    border: $border-base;
    border-width: 0 0 1px;
    margin: 0 0 $spacer--extra-large;

    @include mq($screen-l) {
        margin: 0 0 110px;
    }

    &__content {
        margin: 0 0 $spacer--large;
        padding: 0 $spacer;
        width: 100% !important;

        @include mq($screen-xl) {
            margin-right: 140px;
        }

        [data-content-type="heading"],
        [data-content-type="text"] {
            @include mq($screen-m) {
                text-align: center;
            }

            @include mq($screen-xl) {
                text-align: left;
            }
        }
    }

    &__form {
        background-color: $gray-lighter;
        padding: $spacer--extra-large $spacer;
        flex-shrink: 0;
        width: 100% !important;

        @include mq($screen-m) {
            padding: 84px;
        }

        @include mq($screen-xl) {
            width: $form-width;
            max-width: $form-width;
        }

        .input {
            justify-content: space-between;

            &__field {
                border-color: $white;

                @include mq($screen-m) {
                    max-width: $input-max-width;
                }
            }
        }
    }

    &__button-wrapper {
        display: flex;
        justify-content: center;

        @include mq($screen-m) {
            justify-content: flex-end;
        }
    }

    &__button {
        max-width: 100%;

        @include mq($screen-m) {
            max-width: $input-max-width;
        }
    }

    &--bg {
        background-size: 0 !important; // sass-lint:disable-line no-important

        @include mq($screen-xl) {
            background-size: 75% !important; // sass-lint:disable-line no-important
        }
    }

    .pagebuilder-column-line {
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: $form-width;
        margin: 0 auto $spacer--extra-large;

        @include mq($screen-m) {
            margin: 0 auto 84px;
        }

        @include mq($screen-xl) {
            flex-wrap: nowrap;
            max-width: $max-content-width;
        }
    }

    .pagebuilder-column-group {
        max-width: $max-content-width;
        margin: 0 auto;
    }
}
