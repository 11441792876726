// Styles
.search-wrapper {
    display: flex;
    border-bottom: $border-width-base $border-style-base $border-color-base;
    padding-bottom: $element-wrapper_padding-bottom;
    margin-bottom: $element-wrapper_margin-bottom;

    &.no-results {
        border-bottom: unset;
        padding-bottom: unset;
    }

    .field.search {
        flex-grow: 1;
    }

    .actions {
        align-self: flex-start;
    }
}

.bottom-action-wrapper {
    margin-top: $element-wrapper_margin-top;
    margin-bottom: $element-wrapper_margin-top;
}

.order-approve-manage {
    border-top: $border-width-base $border-style-base $border-color-base;
    padding-top: $element-wrapper_padding-top;
}

.order-approve-manage,
.subaccounts-manage {
    .action {
        display: block;

        &.delete {
            color: $action-delete__color;
        }
    }
}

// General styles
.table {
    .element {
        &-float-none {
            @include mq($screen-m) {
                float: none;
            }
        }

        &-no-visible {
            display: none;
        }
    }

    .table-active {
        display: table-row;
    }

    .col {
        &.actions {
            .action {
                &:after {
                    display: inline-block;
                    content: "|";
                    color: $font-color-base;
                    padding-left: $spacer;
                    padding-right: $spacer;

                    @include mq($screen-m) {
                        display: none;
                    }
                }

                &.reject {
                    &:before {
                        display: none !important; // sass-lint:disable no-important
                    }
                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    .reject-reasons {
        resize: none;
    }
}
